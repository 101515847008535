<template>
    <div class="users">
        <Header></Header>
        <div>
            <div class="d-flex justify-content-center">
                <div class="p-1" style="width: 33vw">
                    <b-card header-class="d-flex" body-class="p-0">
                        <b-card-header class="d-flex">
                            <b-button variant="info" class="d-flex align-self-center" v-b-modal="'modal-function'" >
                                ƒ
                            </b-button>

                            <h5 class="d-inline-block m-auto">
                                {{ $t('users.groups') }}
                            </h5>
                            <b-button variant="success" class="d-flex align-self-center" @click="getUserGroup( 0)">
                                <b-icon class="align-self-center" icon="plus" font-scale="1.5"></b-icon>
                            </b-button>
                        </b-card-header>
                        <perfect-scrollbar class="list-height">
                            <b-list-group-item v-for="(userGroup, index) in userGroupList" :key="index"
                                               class="p-2 d-flex justify-content-between" href="#"
                                               @click="getUserGroup(userGroup.pk)"
                            >
                                <div class="d-flex my-auto">
                                    {{ userGroup.label }}
                                </div>
                            </b-list-group-item>
                        </perfect-scrollbar>

                    </b-card>
                </div>
                <div class="p-1" style="width: 33vw">
                    <b-card header-class="d-flex" body-class="p-0">
                        <b-card-header class="d-flex">
                            <h5 class="d-inline-block m-auto">
                                {{ $t('users.users') }}
                            </h5>
                            <b-button variant="success" class="d-flex align-self-center" @click="getUser(0)">
                                <b-icon class="align-self-center" icon="plus" font-scale="1.5"></b-icon>
                            </b-button>
                        </b-card-header>
                        <perfect-scrollbar class="list-height">
                            <b-list-group-item v-for="(user, index) in userList" :key="index"
                                               class="p-2 d-flex justify-content-between" href="#"
                                               @click="getUser(user.pk)"
                            >
                                <div class="d-flex my-auto">
                                    {{ user.uname }}
                                </div>
                            </b-list-group-item>
                        </perfect-scrollbar>
                    </b-card>
                </div>
                <div class="p-1" style="width: 33vw">
                    <b-card header-class="d-flex" body-class="p-0">
                        <b-card-header class="d-flex">

                            <h5 class="d-inline-block m-auto">
                                {{ $t('users.brands') }}
                            </h5>
                            <b-button variant="success" class="d-flex align-self-center" @click="getBrandIndex(-1)">
                                <b-icon class="align-self-center" icon="plus" font-scale="1.5"></b-icon>
                            </b-button>


                        </b-card-header>
                        <perfect-scrollbar class="list-height">
                            <b-list-group-item v-for="(onebrand, index) in brand" :key="index"
                                               class="p-2 d-flex justify-content-between" href="#"
                                               @click="getBrand(index)">
                                <div class="d-flex my-auto">
                                    {{ onebrand.label }}
                                </div>
                            </b-list-group-item>
                        </perfect-scrollbar>
                    </b-card>
                </div>
            </div>


        </div>
        <modal-user-group/>
        <modal-user/>
        <modal-brand/>
        <modal-function/>
    </div>
</template>

<script>

import Header from '@/components/admin/Header.vue'
import ModalUser from '@/components/admin/ModalUser'
import ModalBrand from '@/components/admin/ModalBrand'
import ModalUserGroup from "@/components/admin/ModalUserGroup";
import ModalFunction from "@/components/admin/ModalFunction";
import {PerfectScrollbar} from 'vue2-perfect-scrollbar'
import {ConfJson} from '@/main';


export default {
    name: 'Admin',
    components: {
        Header,
        ModalUser,
        ModalBrand,
        ModalUserGroup,
        ModalFunction,
        PerfectScrollbar,


    }, computed: {
        userGroupList() {
            return this.$store.state.users.userGroupList
        },
        userList() {
            return this.$store.state.users.userList
        },
        brand() {
            return this.$store.state.users.brand

        },

    },


    created: function () {
        let MyComponent = this;
        let myInterval = setInterval(function () {
            if (ConfJson != null) {
                let old_lang = localStorage.getItem('locale')
                if (old_lang === undefined || old_lang === null || old_lang === "null" || old_lang.length === 0) {
                    MyComponent.$i18n.locale = ConfJson.default_language
                    localStorage.setItem('locale', ConfJson.default_language)
                }
                MyComponent.$store.commit('users/getUserList')
                MyComponent.$store.commit('users/getBrand')
                MyComponent.$store.commit('users/getPool')
                MyComponent.$store.commit('users/getUserGroupList')
                MyComponent.$store.commit('users/getSection', {fkTbSection: 0, index: -1})
                MyComponent.$store.commit('users/getFunction', "get")
                MyComponent.$store.commit('users/getFunction', "post")
                clearInterval(myInterval);
            }
        }, 100);
    },
    methods: {
        getUser: function (pk) {
            this.$store.commit('users/getUser', pk);
            this.$nextTick(() => {
                this.$bvModal.show('modal-user')
            })
        },
        getUserGroup: function (pk) {
            this.$store.commit('users/getUserGroup', pk);
            this.$nextTick(() => {
                this.$bvModal.show('modal-user-group')
            })
        },
        getBrand: function (index) {
            this.$store.commit('users/setBrandIndex', index);
            this.$nextTick(() => {
                this.$bvModal.show('modal-brand')
            })
        },
        getBrandIndex: function (index) {
            this.$store.commit('users/setBrandIndex', index);
            this.$nextTick(() => {
                this.$bvModal.show('modal-brand')
            })
        },
    }
}

</script>
<style>

.list-height {
    height: calc(100vh - 280px)
}

.list-group-item {
    cursor: pointer;

}

.list-group-item:hover {
    opacity: 0.8;

}
</style>
