import $ from 'jquery'
import {ConfJson} from "@/main"


const state = {
    all_grid: []
}

const mutations = {

    insupdGrid(state, index) {
        let obj = {}
        if (index < 0 || index >= state.all_grid.length) {
            obj = {
                "pk": 0,
                "label": "",
                "url_ws": ""
            }
        } else {
            obj = state.all_grid[index]
        }
        $.post(ConfJson.ines_rtc_ws.service_url + "/controller?", {
            "fnct": "insupd_grid",
            "brand": localStorage.getItem("BrandName"),
            "token": localStorage.getItem("token"),
            "data": JSON.stringify(obj),
        }).done(function () {
            getGrid(state);
        }).fail(function (e) {
            console.log(e)
        }).always(function () {

        });
    }, getGrid(state) {
        getGrid(state);
    }, deleteGrid(state, index) {
        $.post(ConfJson.ines_rtc_ws.service_url + "/controller?", {
            "fnct": "del_grid",
            "brand": localStorage.getItem("BrandName"),
            "token": localStorage.getItem("token"),
            "data": JSON.stringify({"pk": state.all_grid[index].pk}),
        }).done(function () {
            getGrid(state);
        }).fail(function (e) {
            console.log(e)
        }).always(function () {
        });
    }
}
export default {
    namespaced: true,
    state,
    mutations
}

function getGrid(state) {

    $.getJSON(ConfJson.ines_rtc_ws.service_url + "/controller?", {
        "fnct": "get_grid",
        "token": localStorage.getItem("token"),
        "brand": localStorage.getItem("BrandName"),
    }).done(function (data) {
        console.log(data);
        if (data != null && data.grid != null && data.grid.length > 0) {
            state.all_grid = data.grid;
        }
    }).fail(function (e) {
        console.log(e)
    }).always(function () {

    });
}