import * as SIP from 'sip.js'
import webAudioPeakMeter from 'web-audio-peak-meter'
import i18n from '@/i18n';
import $ from "jquery";
import {ConfJson} from "@/main";
//import router from "@/router";

const state = {
    interval_write_check: null,
    session: '',
    ua: '',
    registerer: '',
    audio: '',
    callIncoming: false,
    callOutgoing: false,
    onCall: false,
    sipConnected: false,
    audio_muted :false,
}

const mutations = {
    registerUserAgent(state) {
        /* TODO : Fonction de l'appel pour INES-STANDARD
        $.getJSON(ConfJson.ines_ws.service_url + "/controller?", {
            "fnct": "get_brand",
            "token": localStorage.getItem("token"),
            //"brand": localStorage.getItem("BrandName"),
            //"fk_tb_button": "" + fk_button,
        }).done(function (data) {
            console.log(data)

        }).fail(function (e) {
            console.log(e)
        }).always(function () {

        });
*/
        //TODO : CHECK VALUE OF LOCAL STORAGE
        state.sipConnected = false;
        let websocketServer = ConfJson.sip_account.websocket_server;
        let domain = ConfJson.sip_account.domain;
        let displayName = localStorage.getItem('accountName');
        let authorizationUser = localStorage.getItem('accountName');
        let authorizationPassword = ConfJson.sip_account.authorization_password;

        let publicIdentity = "sip:" + authorizationUser + "@" + domain
        state.ua = new SIP.UserAgent({
            uri: SIP.UserAgent.makeURI(publicIdentity),
            logConfiguration: true,
            logBuiltinEnabled: true,
            logLevel: "log",// "debug" | "log" | "warn" | "error"
            displayName: displayName,
            transportOptions: {
                server: websocketServer,
            },
            //stunServers: [obj_data_init.stun_server],
            //traceSip: true,

            authorizationUser: authorizationUser,
            authorizationPassword: authorizationPassword
        });


        state.ua.transport.stateChange.addListener((e) => { //CHECK THE WEBSOCKET CONN !
            if (e + "" === ("Disconnected")) {
                /*console.log("EVENT ! ")
                console.log(e)*/
                state.sipConnected = false
            }
        });


        state.registerer = new SIP.Registerer(state.ua)

        state.ua.start().then(function () {
            state.registerer.register().then(function (a) {
                state.registerer.stateChange.addListener((Registerstate) => {
                    console.log(`Sipjs registerer state changed to ${Registerstate}`);
                    switch (Registerstate) {
                        case SIP.RegistererState.Registered:

                            state.sipConnected = true;
                            state.ua.delegate = {
                                onNotify: (notification) => {
                                    console.log("NOTIFY received");
                                    console.log(notification)
                                    // notification.accept();
                                },
                                onSubscribe: (subscription) => {
                                    console.log("SUBSCRIBE received");
                                    console.log(subscription)
                                    // subscription.accept();
                                },
                                onInvite: function (invitation) {
                                    if (state.session.lenght > 0) {
                                        invitation.reject({statusCode: '486', reasonPhrase: 'Busy Here'})
                                        return
                                    }
                                    console.log('receiving call');
                                    state.session = invitation;

                                    state.callIncoming = true;
                                    addSESSIONDelegate(state)
                                },
                                onMessage: (message) => {
                                    console.log('111122-referral: ', message);
                                    //this.commonNotifyVue(message, 'info');
                                },
                            };

                            break;
                        case SIP.RegistererState.Unregistered:
                            alert(i18n.t('solo.sip_fail_registration'))
                            console.log('Unregistered');
                            break;
                        default:
                            console.log('Unknown registerer state.');
                            throw new Error("Unknown registerer state.");
                    }
                })

                //var logger = state.ua.getLogger();
                //state.ua.getLoggerFactory()
                console.log("#############TRYING TO REGISTER#############")
                console.log(a)

                //addUAdelegate(state)
            }).catch((error) => {
                alert(error)
                // userAgent.isConnected() === false
            });

        }).catch((error) => {
            alert(error)
            // userAgent.isConnected() === false
        });
    }, makeCall(state, PhoneNumber) {
        if (!state.sipConnected) {
            alert(i18n.t('solo.sip_missing_information'))
            return
        }
        if (PhoneNumber.length === 0) {
            return
        }

        let domain =  ConfJson.sip_account.domain;//localStorage.getItem('domain');
        if (PhoneNumber.length > 4) { // gestion du PBX
            PhoneNumber = ConfJson.sip_prefix_out + PhoneNumber
        }
        let target = "sip:" + PhoneNumber + "@" + domain;
        //const inviterOptions: SIP.InviterOptions = {delegate={o}};
        try {
            state.session = new SIP.Inviter(state.ua, SIP.UserAgent.makeURI(target))
        } catch (e) {
            alert(i18n.t('solo.number_error'))
            return
            }

        //let logger = state.ua.getLogger("sip.Inviter")
        //let logger = state.ua.getLoggerFactory("sip.Inviter")

       /* state.session.logger.observe(state.session.logger, function(e) {

        })*/

        //console.log()
        localStorage.setItem("last_call", PhoneNumber)
        state.callOutgoing = true
        state.session.invite(getOptions()).catch(function (e) {
            e += ""
            if (e.includes("device not found")) {
                alert(i18n.t('solo.error_device'))
            } /*else {
                alert(i18n.t('solo.other_error') + e )
            }*/

        });
        addSESSIONDelegate(state);
    }, cancelCall(state) {
        state.session.cancel(getOptions);
    }, answerCall(state) {
        state.session.accept(getOptions);
        state.callIncoming = false
    }, endCall(state) {
        //alert("BYE")
        state.session.dispose(null);
        //state.session.bye(null);

    }, rejectCall(state) {
        state.callIncoming = false
        state.session.reject({statusCode: '486', reasonPhrase: 'Busy Here'})
    }, muteAudio(state) {
        //state.audio_muted = !state.audio_muted
        if (!state.onCall) {
            return
        }
        var pc = state.session.sessionDescriptionHandler.peerConnection;
        pc.getLocalStreams().forEach(function (stream) {
            stream.getAudioTracks().forEach(function (track) {
                state.audio_muted = !state.audio_muted
                track.enabled = !track.enabled;
            });
        });
    },
}

/*function addUAdelegate(state) {

    state.ua.delegate = {

        onDisconnect: function (error) {

            console.log("DISCONNECTED: ", error)
        },
        onInvite: function (invitation) {
            if (state.session.lenght > 0) {
                invitation.reject({statusCode: '486', reasonPhrase: 'Busy Here'})
                return
            }
            console.log('receiving call');
            state.session = invitation;
            state.callIncoming = true;
            addSESSIONDelegate(state)
        },
        onMessage: function (message) {
            console.log("onMessage: ", message)
        },
        onNotify: function (notification) {
            console.log("onNotify: ", notification)
        },
        incomingSession: function () {
            console.log("incomingSession")
        },
    }
}*/

function addSESSIONDelegate(state) {
    state.session.delegate = {
        onBye: function (options) {//RACCROCHER PAR LE CORRESPONDANT
            //state.session.dispose()
            console.log("onBye: ", options)
        },
    }
    state.session.stateChange.addListener((newState) => {
        switch (newState) {
            case SIP.SessionState.Establishing:
                console.log("ESTABLISHING");
                break;
            case SIP.SessionState.Established:
                //state.audio.play();
                state.onCall = true;
                state.callIncoming = false;
                state.callOutgoing = false;
                setupRemoteMedia(state);



                console.log("ESTABLISHED");
                break;
            case SIP.SessionState.Terminated:
                console.log("TERMINATED");
                clearInterval(state.interval_write_check);
                state.onCall = false;
                state.callIncoming = false;
                state.callOutgoing = false;
                state.session.stateChange.removeListener();
                state.session = '';
                $("#my-peak-meter-in, #my-peak-meter-out").html('');

                break;
            default:

                break;
        }
    });
}

function setupRemoteMedia(state) {
    let mediaElement = document.getElementById('remoteAudio');

    let remoteStream = new MediaStream();
    state.session.sessionDescriptionHandler.peerConnection.getReceivers().forEach((receiver) => {
        if (receiver.track) {
            remoteStream.addTrack(receiver.track);
        }
    });
    mediaElement.srcObject = remoteStream;
    mediaElement.play();
    createVuMeter("my-peak-meter-out", remoteStream)
    var pc = state.session.sessionDescriptionHandler.peerConnection;
    pc.getLocalStreams().forEach(function (stream) {
        createVuMeter("my-peak-meter-in",stream)
    });
    /*navigator.webkitGetUserMedia({audio: true, video: false}, function (stream) {
        createVuMeter("my-peak-meter-in", stream)
    }, function () {

    })*/
}

function createVuMeter(idElem, stream) {
    //let webAudioPeakMeter = require('web-audio-peak-meter');
    let peakMeter = webAudioPeakMeter();
    let myMeterElement = document.getElementById(idElem);

    let audioCtx = new window.AudioContext();
    let sourceNode = audioCtx.createMediaStreamSource(stream);
    let analyser = audioCtx.createAnalyser();
    sourceNode.connect(analyser);
    let meterNode = peakMeter.createMeterNode(sourceNode, audioCtx);
    peakMeter.createMeter(myMeterElement, meterNode, {borderSize: 10, backgroundColor: "white", tickColor: "#000"});
    sourceNode.addEventListener('play', function () {
        audioCtx.resume();
    });

    /*    let audioContext = new AudioContext(); // NEW!!
        let analyser = audioContext.createAnalyser();
        let microphone = audioContext.createMediaStreamSource(stream);
        let javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);

        analyser.smoothingTimeConstant = 0.3;
        analyser.fftSize = 1024;

        microphone.connect(analyser);
        analyser.connect(javascriptNode);
        javascriptNode.connect(audioContext.destination);

        //canvasContext = $("#canvas")[0].getContext("2d");
        let canvasContext = document.getElementById(idElem);
        canvasContext = canvasContext.getContext("2d");

        javascriptNode.onaudioprocess = function () {
            var array = new Uint8Array(analyser.frequencyBinCount);
            analyser.getByteFrequencyData(array);
            var values = 0;

            var length = array.length;
            for (var i = 0; i < length; i++) {
                values += array[i];
            }

            var average = values / length;
            canvasContext.clearRect(0, 0, 60, 130);
            canvasContext.fillStyle = '#00ff00';
            canvasContext.fillRect(0, 130 - average, 60, 130);
        }*/
}


function getOptions() {
    const audio = document.getElementById('remoteAudio')
    const audio2 = document.getElementById('localAudio')
    //SessionDescriptionHandlerOptions.
    return {
        media: {
            constraints:
                {audio: true, video: false},
            render:
                {remote: audio, local: audio2}
        },
        //SessionDescriptionHandlerOptions:{iceGatheringTimeout : 1000}
    }
}



export default {
    namespaced: true,
    state,
    mutations
}



