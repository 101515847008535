<template>
    <b-modal id="modal-button" size="xl">
        <template v-slot:modal-title>{{$t('modal_button.title')}}</template>
        <b-input-group class="mb-2">
            <b-input-group-prepend is-text class="input-prepend-style">
                {{$t('modal_button.id')}}
            </b-input-group-prepend>
            <b-input class="" v-bind:placeholder="$t('modal_config.label')" v-model="button.button.pk" autocomplete="off"/>
        </b-input-group>

        <b-input-group class="mb-2">
            <b-input-group-prepend is-text class="input-prepend-style">
                {{$t('modal_button.title_btn')}}
            </b-input-group-prepend>
            <b-input class="" v-bind:placeholder="$t('modal_config.label')" v-model="button.button.title" autocomplete="off"/>
        </b-input-group>

        <b-input-group class="justify-content-around mb-3">
            <b-form-checkbox v-model="button.button.default_state">{{$t('modal_button.default_state')}}
            </b-form-checkbox>
            <b-form-checkbox v-model="button.button.global">{{$t('modal_button.generic_button')}}</b-form-checkbox>
            <b-form-checkbox v-model="button.button.diffusion">{{$t('modal_button.diffusion')}}</b-form-checkbox>
            <b-form-checkbox v-model="button.button.conference">{{$t('modal_button.conference')}}</b-form-checkbox>
        </b-input-group>
        <div class="d-flex justify-content-between mb-3">
            <div class="w-50">
                <b-input-group class="w-75">
                    <b-input-group-prepend is-text class="input-prepend-style">
                        {{$t('modal_button.color_on')}}
                    </b-input-group-prepend>
                    <b-form-select class="" v-model="button.button.color_on"
                                   :class="button.button.color_on.startsWith('outline-') ? button.button.color_on.replace('outline', 'border'):'bg-' + button.button.color_on">
                        <option v-for="(color) in color" :key="color.value" :value="color.value"
                                :class="'bg-white'">
                            {{ color.text }}
                        </option>
                    </b-form-select>
                </b-input-group>
            </div>
            <div class="w-50">
                <b-input-group class="w-75">
                    <b-input-group-prepend is-text class="input-prepend-style">
                        {{$t('modal_button.color_off')}}
                    </b-input-group-prepend>
                    <b-form-select class="" v-model="button.button.color_off"
                                   :class="button.button.color_off.startsWith('outline-') ? button.button.color_off.replace('outline', 'border'):'bg-' + button.button.color_off"
                    >
                        <option v-for="(color) in color" :key="color.value" :value="color.value"
                                :class="'bg-white'/*color.value.startsWith('outline-') ? 'border ' + color.value.replace('outline', 'border'):'bg-' + color.value*/">
                            {{ color.text }}
                        </option>
                    </b-form-select>
                </b-input-group>
            </div>
        </div>
        <div class="w-50">
            <b-input-group class="">
                <b-input-group-prepend is-text class="input-prepend-style">
                    {{$t('modal_button.button_type')}}
                </b-input-group-prepend>
                <b-form-select class="w-auto" v-model="button.button.type" :options="button_type">
                </b-form-select>
            </b-input-group>
        </div>
        <!--              MEDIA PART !                -->
        <MediaList direction="0"/>
        <MediaList direction="1"/>
        <MediaList direction="2"/>
        <template v-slot:modal-footer>
            <b-button class="mr-auto" variant="danger" @click="deleteMedia($event,2, index)">
                <b-icon icon="trash" font-scale="1.7" class="align-self-center"/>
            </b-button>
            <b-button variant="warning" @click="$bvModal.hide('modal-button')">
                <b-icon icon="x" font-scale="1.7" class="align-self-center"/>
            </b-button>

            <b-button variant="success" @click="insupdMedia($event,2,index)">
                <b-icon icon="check" font-scale="1.7" class="align-self-center"/>
            </b-button>

        </template>
    </b-modal>
</template>

<script>
    //import {ConfJson} from "@/main";
    import MediaList from "@/components/rtc/MediaList"

    export default {
        name: "ConfigButton",
        props: {},
        data: function () {
            return {
                color: [
                    {value: "primary", text: this.$t('modal_button.color_blue')},
                    {value: "secondary", text: this.$t('modal_button.color_grey')},
                    {value: "success", text: this.$t('modal_button.color_green')},
                    {value: "warning", text: this.$t('modal_button.color_orange')},
                    {value: "danger", text: this.$t('modal_button.color_red')},
                    {value: "info", text: this.$t('modal_button.color_light_blue')},
                    {value: "light", text: this.$t('modal_button.color_white')},
                    {value: "dark", text: this.$t('modal_button.color_black')},
                    {value: "outline-primary", text: this.$t('modal_button.color_border_blue')},
                    {value: "outline-secondary", text: this.$t('modal_button.color_border_grey')},
                    {value: "outline-success", text: this.$t('modal_button.color_border_green')},
                    {value: "outline-warning", text: this.$t('modal_button.color_border_orange')},
                    {value: "outline-danger", text: this.$t('modal_button.color_border_red')},
                    {value: "outline-info", text: this.$t('modal_button.color_border_light_blue')},
                    {value: "outline-light", text: this.$t('modal_button.color_border_white')},
                    {value: "outline-dark", text: this.$t('modal_button.color_border_black')}
                ],
                button_type: [
                    {
                        "value": 0,
                        "text": this.$t('modal_button.button_type0')
                    },
                    {
                        "value": 1,
                        "text": this.$t('modal_button.button_type1')
                    },
                    {
                        "value": 2,
                        "text": this.$t('modal_button.button_type2')
                    }
                ],
            }
        },
        components: {
            MediaList
        },
        created: function () {

        },
        computed: {
            button() {
                return this.$store.state.button
            },
        },
        methods: {
            changeLocale() {
                localStorage.setItem('locale', this.$i18n.locale)
            }, insupdButton(evt) {
                evt.preventDefault();
                this.$store.commit('button/insupdButton')
            }, deleteButton(evt) {
                evt.preventDefault();
                this.$store.commit('button/deleteButton')
            }
        },

    }
</script>

<style scoped>
    .input-prepend-style > div {
        display: block; /* Instead of display:block */

        width: 150px;
        align-items: center;
        text-align: center;
    }

    .w-100px {
        width: 100px !important;
    }
</style>