<template>
    <b-modal id="modal-section">
        <template v-slot:modal-title>{{ $t('modal_section.title') }}</template>
        <b-form @submit="insupdSection">
            <b-form-group
                          class="mb-1"
                          v-bind:label="$t('modal_section.id')"
            >
                <b-form-input
                    v-model="section.pk"

                />

            </b-form-group>
            <b-form-group v-bind:label="$t('modal_section.label')"
                          class="mb-1"
            >
                <b-form-input
                    v-model="section.label"
                    required
                    v-bind:placeholder="$t('modal_section.label_empty')"
                />
            </b-form-group>

            <b-form-group v-if="section.pk>0"
                          v-bind:section="s"
                          class="mb-1"
            >
                <b-form-input
                    v-model="section.fk_tb_section"
                    required
                />

            </b-form-group>
        </b-form>
        <template #modal-footer>
            <div>
                <b-button
                    variant="danger"
                    v-if="section.pk>0"
                    @click="delSection"
                >
                    <b-icon class="align-self-center" icon="trash" font-scale="1.5"></b-icon>
                </b-button>
            </div>
            <b-button
                @click="insupdSection"
                variant="success"
            >
                <b-icon class="align-self-center" icon="check2" font-scale="1.5"></b-icon>
            </b-button>
            <modal-section/>
        </template>
    </b-modal>
</template>

<script>


export default {
    name: "ModalSection",
    data() {
        return {}
    },
    computed: {
        section() {
            return this.$store.state.users.currentSection
        },
    },
    methods: {
        insupdSection(evt) {
            evt.preventDefault();
            this.$store.commit('users/insupdSection');
            this.$nextTick(() => {
                this.$bvModal.hide('modal-section')
            })

        },


        delSection(evt) {
            evt.preventDefault();
            this.$store.commit('users/delSection');
            this.$nextTick(() => {
                this.$bvModal.hide('modal-section')
            })

        },


    }
}
</script>