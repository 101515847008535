<template>
    <div>
        <Loading v-if="loading"/>
        <div v-else class="d-flex  p-1 vh-100 ">
            <div class="bg-light flex-fill h-100 w-25">
                <b-card no-body class="p-1">
                    <div class=" mb-1 ">
                        <div class="d-flex">
                            <img alt="ines-rtc logo" src="@/assets/ines.png">
                            <div class="d-flex w-100 align-self-center justify-content-end">
                                <b-button variant="info" class="d-flex p-2" v-b-modal.modal-config>
                                    <b-icon icon="house" font-scale="1.5" class="align-self-center"/>
                                </b-button>
                                <b-button variant="secondary" class="d-flex  ml-2 p-2" v-b-modal.modal-config>
                                    <b-icon icon="gear" font-scale="1.5" class="align-self-center"/>
                                </b-button>
                                <modal-config title="Line Modal"/>
                                <b-button variant="danger" class="d-flex ml-2 p-2" v-on:click="delogMe()">
                                    <b-icon icon="box-arrow-right" font-scale="1.5" class="align-self-center"/>
                                </b-button>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <div>
                                {{version}}
                            </div>
                            <div>
                                {{accountName}}
                            </div>
                        </div>
                    </div>
                    <b-card no-body class="mb-1">
                        <b-tabs card align="center">
                            <b-tab title="Auditeurs" active>
                                <b-card-text class="content-size">RECHERCHE AUDITEUR</b-card-text>
                            </b-tab>
                            <b-tab title="Appels">
                                <b-card-text class="content-size">APPEL ENTRANT / EN COURS</b-card-text>
                            </b-tab>
                            <b-tab title="Historique des appels">
                                <b-card-text class="content-size">HISTORIQUE DES APPELS</b-card-text>
                            </b-tab>
                        </b-tabs>
                    </b-card>
                    <b-card no-body>
                        <b-tabs card align="center">
                            <b-tab title="Listes" active>
                                <b-card-text class="content-size">LISTES AUDITEUR</b-card-text>
                            </b-tab>
                            <b-tab title="INES">
                                <b-card-text class="content-size">INES</b-card-text>
                            </b-tab>
                        </b-tabs>
                    </b-card>
                </b-card>
            </div>
            <div class="bg-light flex-fill p-1 h-100 w-75">
                <b-tabs content-class="">
                    <b-tab title="+" active> <Listener index="-1"/></b-tab>
                    <b-tab v-for="(listener) in listeners" :key="listener" :title="listener">
                        <Listener :index="listener"/>
                    </b-tab >
                </b-tabs>
            </div>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    /*
    *  IMPORT COMPONENTS
    * */
    import Loading from "@/components/Loading"
    import Listener from "@/components/standard/Listener"

    /*
    *  IMPORT LIBS
    * */
    import {ConfJson, version} from "@/main";

    //import {PerfectScrollbar} from 'vue2-perfect-scrollbar'

    export default {
        name: 'Standard',
        metaInfo: {
            title: 'Standard',
            titleTemplate: '%s - Ines',
        },
        created: function () {
            //console.log(ConfJson)
            let MyComponent = this;
            let myInterval = setInterval(function () {
                if (ConfJson != null) {
                    MyComponent.loading = false;

                    clearInterval(myInterval);
                }
            }, 100);

        },
        components: {
            Loading,
            Listener

        },
        data: function () {
            return {
                version: version,
                accountName: localStorage.getItem("accountName"),
                loading: true,
                listeners: ["1","2"],
            }
        },
        computed: {},
        methods: {
            newKey: function (evt) {
                evt.preventDefault();
                this.$store.commit('key/newKey')
            },
            deleteKey: function (name, year, key) {
                this.$store.commit('key/deleteKey', {"name": name, "year": year, "key": key})
            },
            delogMe: function () {
                this.$store.commit('login/delogMe')
            }
        }
    }
</script>

<style scoped>
    .content-size {
        height: calc(100vh / 2 - 161px) !important;
    }
</style>