<template>
    <div>
        <hr>
        <div>
            <div class="mb-1 d-flex justify-content-between">
                <em><span class="col-3">{{$t('modal_button_media_list.media_direction' + this.direction)}}</span>
                    <b-icon icon="box-arrow-right" font-scale="1.5" class=""/>
                </em>
                <b-button variant="success" class="p-0" @click="insMedia($event)">
                    <b-icon icon="plus" font-scale="2" class="align-self-center"/>
                </b-button>
            </div>
            <div v-for="(media, index) in mediaArray" :key="index" class="border p-2 mb-1 rounded style-2">
                <b-input-group>
                    <b-input-group-prepend is-text class="">
                        {{media.pk}}
                    </b-input-group-prepend>
                    <b-form-select v-if="direction === '2' " class="col-1" v-model="media.media_type"
                                   :options="media_type">
                    </b-form-select>
                    <b-form-select class="" v-model="media.media_type" :options="media_type">
                    </b-form-select>
                    <b-form-select class="" v-model="media.in_out_type" :options="out_type">
                    </b-form-select>
                    <b-form-select class="" v-model="media.fk_tb_grid">
                        <b-form-select-option v-for="(grid) in all_grid" :key="grid.pk" :value="grid.pk">
                            {{grid.label}}
                        </b-form-select-option>
                    </b-form-select>
                    <b-input-group-append>
                        <b-button variant="info" @click="testMedia($event,index)">
                            <b-icon icon="play" class="align-self-center"/>
                        </b-button>
                        <b-button variant="danger" @click="deleteMedia($event, index)">
                            <b-icon icon="trash" class="align-self-center"/>
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
                <b-input-group class="mt-1">
                    <b-form-select class="" v-if="media.in_out_type === 'device'" v-model="media.value"
                                   :options="media_type"/>

                    <b-form-select class="" v-if="media.in_out_type === 'stream' && media.media_type === 0"
                                   v-model="media.codec"
                                   :options="codec_media_audio_type"/>
                    <b-form-select class="" v-if="media.in_out_type === 'stream' && media.media_type === 1"
                                   v-model="media.codec"
                                   :options="codec_media_video_type"/>
                    <b-form-input class="" v-if="media.in_out_type === 'stream'" v-model="media.value" autocomplete="off"/>


                    <b-form-select class="" v-if="media.in_out_type === 'blackmagic' && media.media_type === 1"
                                   v-model="media.codec" :options="backmagic_mode"/>
                    <b-form-select class="" v-if="media.in_out_type === 'blackmagic'"
                                   v-model="media.value">
                        <b-form-select-option v-for="(index) in backmagic_nb_line" :key="index" :value="index -1">
                            {{'Black Magic ' + index}}
                        </b-form-select-option>
                    </b-form-select>

                    <b-form-input class="" v-if="media.in_out_type === 'file'" v-model="media.value" autocomplete="off"/>

                    <b-form-select class="" v-if="media.in_out_type === 'jackd' && media.media_type === 0"
                                   v-model="media.value">
                        <b-form-select-option v-for="(index) in 32" :key="index" :value="index">
                            {{index}}
                        </b-form-select-option>
                    </b-form-select><!--TODO : MANQUE LE BLACK MAGIC -->

                    <b-form-input class="" v-if="media.in_out_type === 'custom'" v-model="media.value" autocomplete="off"/>

                    <b-form-input class="" v-if="media.in_out_type === 'custom_all'" v-model="media.value" autocomplete="off"/>

                </b-input-group>
                <div class="info-media text-info text-center"
                     v-if="media.in_out_type === 'custom_all' && direction ==='0'">
                    {{$t('modal_button_media_list.custom_all_msg1')}}
                </div>
                <div class="info-media text-info text-center" v-else-if="media.in_out_type === 'custom_all'">
                    {{$t('modal_button_media_list.custom_all_msg2')}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {ConfJson} from "@/main";

    export default {
        name: "MediaList",
        props: {
            direction: String
        },
        data: function () {
            return {
                media_type: [
                    {
                        "value": 0,
                        "text": this.$t('modal_button_media_list.media_type0')
                    },
                    {
                        "value": 1,
                        "text": this.$t('modal_button_media_list.media_type1')
                    },
                    {
                        "value": 2,
                        "text": this.$t('modal_button_media_list.media_type2')
                    }
                ],
                in_type: [
                    {
                        "value": "default",
                        "text": this.$t('modal_button_media_list.media_in_out_type_default')
                    },
                    {
                        "value": "device",
                        "text": this.$t('modal_button_media_list.media_in_out_type_device')
                    },
                    {
                        "value": "stream",
                        "text": this.$t('modal_button_media_list.media_in_out_type_stream')
                    },
                    {
                        "value": "file",
                        "text": this.$t('modal_button_media_list.media_in_out_type_file')
                    },
                    {
                        "value": "test",
                        "text": this.$t('modal_button_media_list.media_in_out_type_test')
                    },
                    {
                        "value": "blackmagic",
                        "text": this.$t('modal_button_media_list.media_in_out_type_blackmagic')
                    },
                    {
                        "value": "alsa_linux",
                        "text": this.$t('modal_button_media_list.media_in_out_type_alsa_linux')
                    },
                    {
                        "value": "jackd",
                        "text": this.$t('modal_button_media_list.media_in_out_type_jackd')
                    },
                    {
                        "value": "custom",
                        "text": this.$t('modal_button_media_list.media_in_out_type_custom')
                    },
                    {
                        "value": "custom_all",
                        "text": this.$t('modal_button_media_list.media_in_out_type_custom_all')
                    }
                ],
                out_type: [
                    {
                        "value": "default",
                        "text": this.$t('modal_button_media_list.media_in_out_type_default')
                    },
                    {
                        "value": "device",
                        "text": this.$t('modal_button_media_list.media_in_out_type_device')
                    },
                    {
                        "value": "stream",
                        "text": this.$t('modal_button_media_list.media_in_out_type_stream')
                    },
                    {
                        "value": "blackmagic",
                        "text": this.$t('modal_button_media_list.media_in_out_type_blackmagic')
                    },
                    {
                        "value": "jackd",
                        "text": this.$t('modal_button_media_list.media_in_out_type_jackd')
                    },
                    {
                        "value": "custom",
                        "text": this.$t('modal_button_media_list.media_in_out_type_custom')
                    },
                    {
                        "value": "custom_all",
                        "text": this.$t('modal_button_media_list.media_in_out_type_custom_all')
                    }
                ],
                codec_media_audio_type: ["OPUS", "PCMU", "PCMA", "G722"],
                codec_media_video_type: ["VP8", "VP9", "H264"],
                backmagic_nb_line: ConfJson.black_magic_decklink.nb_line,
                backmagic_mode: [
                    {"value": "auto", "text": "Automatic detection"},
                    {"value": "ntsc", "text": "NTSC SD 60i"},
                    {"value": "ntsc2398", "text": "NTSC SD 60i (24 fps)"},
                    {"value": "pal", "text": "PAL SD 50i"},
                    {"value": "ntsc-p", "text": "NTSC SD 60p"},
                    {"value": "pal-p", "text": "PAL SD 50p"},
                    //
                    {"value": "1080p2398", "text": "HD1080 23.98p"},
                    {"value": "1080p24", "text": "HD1080 24p"},
                    {"value": "1080p25", "text": "HD1080 25p"},
                    {"value": "1080p2997", "text": "HD1080 29.97p"},
                    {"value": "1080p30", "text": "HD1080 30p"},
                    //
                    {"value": "1080i50", "text": "HD1080 50i"},
                    {"value": "1080i5994", "text": "HD1080 59.94i"},
                    {"value": "1080i60", "text": "HD1080 60i"},
                    {"value": "1080p50", "text": "HD1080 50p"},
                    {"value": "1080p5994", "text": "HD1080 59.94p"},
                    //
                    {"value": "1080p60", "text": "HD1080 60p"},
                    {"value": "720p50", "text": "HD720 50p"},
                    {"value": "720p5994", "text": "HD720 59.94p"},
                    {"value": "720p60", "text": "HD720 60p"},
                    {"value": "1556p2398", "text": "2k 23.98p"},
                    //
                    {"value": "1556p24", "text": "2k 24p"},
                    {"value": "1556p25", "text": "2k 25p"},
                    {"value": "2160p2398", "text": "4k 23.98p"},
                    {"value": "2160p24", "text": "4k 24p"},
                    {"value": "2160p25", "text": "4k 25p"},
                    //
                    {"value": "2160p2997", "text": "4k 29.97p"},
                    {"value": "2160p30", "text": "4k 30p"},
                    {"value": "2160p50", "text": "4k 50p"},
                    {"value": "2160p5994", "text": "4k 59.94p"},
                    {"value": "2160p60", "text": "4k 60p"},
                ],
            }
        },
        computed: {
            mediaArray() {
                switch (this.direction) {
                    case "0":
                        return this.$store.state.button.direction0;
                    case "1":
                        return this.$store.state.button.direction1;
                    case "2":
                        return this.$store.state.button.direction2;
                }
                return []
            },
            all_grid() {
                return this.$store.state.grid.all_grid
            }
        },
        created: function () {
            //console.log(this.direction)
        },
        methods: {
            insMedia(evt) { //CREATE NEW MEDIA (UPDATE WHEN BUTTON SAVE)
                evt.preventDefault();
                if (this.all_grid === null || this.all_grid.length === 0) {
                    alert(this.$t('modal_button_media_list.error_missing_grid'));
                    return
                }
                this.$store.commit('button/insMedia', {
                    fk_tb_grid: this.all_grid[0].pk,
                    direction: parseInt(this.direction)
                })
            }, testMedia(evt, index) {
                evt.preventDefault();
                this.$store.commit('button/testMedia', {direction: parseInt(this.direction), index: index})
            }, deleteMedia(evt, index) {
                evt.preventDefault();
                this.$store.commit('button/deleteMedia', {direction: parseInt(this.direction), index: index})
            }
        }

    }
</script>

<style scoped>

</style>