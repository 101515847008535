<template>

    <b-modal id="modal-config" size="lg">
        <template v-slot:modal-title>{{$t('modal_config.title')}}</template>
        <div class="d-flex">
            <div class="p-2 w-100">
                <b-form-checkbox
                        v-model="autoConnectPretalk"
                        @change="changeAutoConnectPretalk"
                > {{$t('modal_config.auto_co_pretalk_msg')}}
                </b-form-checkbox>
                <b-form-checkbox
                        v-model="autoConnectPretalkRemember"
                        @change="changeAutoConnectPretalkRemember"
                > {{$t('modal_config.auto_co_pretalk_save_msg')}}
                </b-form-checkbox>
            </div>
            <div>{{$t('modal_config.language')}} :
                <b-form-select class="w-auto" v-model="$i18n.locale" :options="$i18n.availableLocales"
                               @change="changeLocale">
                    <!--<option v-for="(lang) in $i18n.availableLocales" :key="lang" :value="lang">{{ lang }}</option>-->
                </b-form-select>
            </div>

        </div>
        <hr>
        <div class="mb-1 d-flex justify-content-between">
            <em>{{$t('modal_config.grid_title')}}</em>
            <b-button variant="success" class="p-0" @click="insupdGrid($event,-1)">
                <b-icon icon="plus" font-scale="2" class="align-self-center"/>
            </b-button>
        </div>
        <div>
            <b-input-group class="mb-2" v-for="(grid,index) in all_grid" :key="index">
                <b-input-group-prepend is-text>
                    {{grid.pk}}
                </b-input-group-prepend>
                <b-input class="" v-bind:placeholder="$t('modal_config.label')" v-model="grid.label" autocomplete="off"/>
                <b-input class="" v-bind:placeholder="$t('modal_config.url')" v-model="grid.url_ws" autocomplete="off"/>
                <b-input-group-append>
                    <b-button variant="success" @click="insupdGrid($event,index)">
                        <b-icon icon="check2" class="align-self-center" />
                    </b-button>
                    <b-button variant="danger" @click="deleteGrid($event,index)">
                        <b-icon icon="trash" class="align-self-center" />
                    </b-button>
                </b-input-group-append>
            </b-input-group>
        </div>
        <hr>
        <div class="mb-1 d-flex justify-content-between">
            <em>{{$t('modal_config.button_title')}}</em>
            <b-button variant="success" class="p-0" @click="insupdButton(new Button(0,'new',true,$('#coloron-button').val(),$('#coloroff-button').val(),0, false), -1)">
                <b-icon icon="plus" font-scale="2" class="align-self-center"/>
            </b-button>
        </div>
        <div class="btn-container d-flex border-dash-style rounded btn-style-container">
            <b-button class="mb-2" v-for="(button,index) in all_button_global" :key="index" @click="openButtonModal(index)" :variant="button.color_on.length === 0 ? 'primary' : button.color_on"><b-icon icon="globe2" font-scale="1" class="align-self-center"/> {{button.title.length === 0 ? button.pk : button.title}}</b-button>
        </div>
    </b-modal>
</template>

<script>
    import {ConfJson} from "@/main";


    export default {
        name: "ConfigModal",
        props: {},
        data: function () {
            return {
                autoConnectPretalk: localStorage.getItem("autoConnectPretalk") || false,
                autoConnectPretalkRemember: localStorage.getItem("autoConnectPretalkRemember") || false,
            }
        },
        components: {},
        created: function () {
            //console.log(ConfJson)
            let MyComponent = this;
            let myInterval = setInterval(function () {
                if (ConfJson != null) {
                    MyComponent.$store.commit('grid/getGrid');
                    MyComponent.$store.commit('button/getButtonGlobal');
                    //console.log(all_grid())
                    clearInterval(myInterval);
                }
            }, 100);

        },
        computed: {
            all_grid() {
                return this.$store.state.grid.all_grid
            }, all_button_global() {
                return this.$store.state.button.all_button_global
            }, index_modal() {
                return this.$store.state.button.index_modal
            }
        },
        methods: {
            changeLocale() {
                localStorage.setItem('locale', this.$i18n.locale)
            }, changeAutoConnectPretalk(value) {
                localStorage.setItem('autoConnectPretalk', value)
            }, changeAutoConnectPretalkRemember(value) {
                localStorage.setItem('autoConnectPretalkRemember', value)
            },
            insupdGrid(evt, index) {
                evt.preventDefault();
                this.$store.commit('grid/insupdGrid', index)
            }, deleteGrid(evt, index) {
                evt.preventDefault();
                /*console.log(index)*/
                this.$store.commit('grid/deleteGrid', index)
            }, openButtonModal(index) {
                this.$store.commit('button/getButton', {index:index,obj: this})
                this.$nextTick(() => {
                    this.$bvModal.show('modal-button')
                })
            },
        },

    }
</script>

<style scoped>
    .border-dash-style {
        flex-wrap: wrap;
        padding: 5px;
        border: 2px dashed rgba(0, 0, 0, 0.5);
    }
    .border-dash-style > button {
        width: 75px;
        word-wrap: break-word;
        min-height: 40px;
        margin: 2px;
    }
</style>