<template>
    <b-modal id="modal-user-group" size="lg">
        <template v-slot:modal-title>{{ $t('modal_user_group.title') }}</template>
        <div>
            <div>
                <b-form @submit="insupdUserGroup">
                    <b-form-group v-if="userGroup.pk>0"
                                  class="mb-1"
                                  v-bind:label="$t('modal_user_group.id')"
                    >
                        <b-form-input
                            v-model="userGroup.pk"
                            disabled
                        />
                    </b-form-group>
                    <b-form-group
                        v-bind:label="$t('modal_user_group.label')"
                        class="mb-1"
                    >
                        <b-form-input
                            v-model="userGroup.label"
                            required
                            v-bind:placeholder="$t('modal_user_group.label_empty')"
                        />

                    </b-form-group>
                </b-form>
            </div>

            <div>
                <b-form-group
                        v-bind:label="$t('modal_user_group.section')"
                        class="mb-1"
                >
<!--                    <b-list-group-item v-for="(onesection, index) in section[0]" :key="index">

                        <div class="d-flex my-auto">
                            <b-icon icon="pencil-square" @click="getSection(index)"></b-icon>

                            {{ onesection.pk }}
                            -
                            {{ onesection.label }}
                            <b-card-body class="text-right p-1">
                                <b-form-checkbox v-model="onesection.checked">
                                </b-form-checkbox>
                                <b-form-checkbox v-model="onesection.checked">
                                </b-form-checkbox>
                            </b-card-body>
                            <b-button variant="outline-success" class="d-flex align-self-right" @click="getSectionIndex(-1)">
                                <b-icon class="align-self-center" icon="chevron-down" font-scale="1.5"></b-icon>
                            </b-button>
                        </div>
                    </b-list-group-item>-->
                    <sections :label="tree.label" :nodes="tree.nodes"></sections>
                </b-form-group>

            </div>
        </div>
        <modal-section/>
        <template #modal-footer>
            <div class="w-100 d-flex justify-content-between">
                <div>
                    <b-button
                        variant="danger"
                        v-if="userGroup.pk>0"
                        @click="delUserGroup"
                    >
                        <b-icon class="align-self-center" icon="trash" font-scale="1.5"></b-icon>
                    </b-button>
                </div>
                <b-button @click="duplicateUserGroup" variant="info" v-if="userGroup.pk>0">
                    <b-icon icon="files"></b-icon>
                </b-button>
                <b-button
                    @click="insupdUserGroup"
                    variant="success"

                >
                    <b-icon class="align-self-center" icon="check2" font-scale="1.5"></b-icon>
                </b-button>
            </div>

        </template>
    </b-modal>
</template>

<script>
import i18n from "@/i18n";
import ModalSection from "@/components/admin/ModalSection.vue";
import Sections from "@/components/admin/Sections";

export default {
    name: "ModalUserGroup",
    components: {
        Sections,
        ModalSection,

    },
    data() {
        return {
            tree : {
                label: 'root',
                nodes: [
                    {
                        label: 'item1',
                        nodes: [
                            {
                                label: 'item1.1'
                            },
                            {
                                label: 'item1.2',
                                nodes: [
                                    {
                                        label: 'item1.2.1'
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        label: 'item2'
                    }
                ]
            }
        }
    },
    computed: {
        userGroup() {
            return this.$store.state.users.userGroup
        },
        section() {
            return this.$store.state.users.section
        },


    },
    methods: {
        insupdUserGroup(evt) {
            evt.preventDefault();
            this.$store.commit('users/insupdUserGroup');
            this.$nextTick(() => {
                this.$bvModal.hide('modal-user-group')
            })

        },
        delUserGroup(evt) {
            evt.preventDefault();
            this.$store.commit('users/delUserGroup');
            this.$nextTick(() => {
                this.$bvModal.hide('modal-user-group')
            })

        },
        duplicateUserGroup: function () {
            var newName = prompt(i18n.t('modal_user.duplicate_question'), '');
            if (newName == null || newName === "") {
                return
            } else {
                this.user_group.pk = 0;
                this.user_group.label = newName
                console.log(newName)
                this.$store.commit('users/insupdUserGroup');
                this.$nextTick(() => {
                    this.$bvModal.hide('modal-user-group')
                })
            }
        },
        getSectionIndex: function (index) {
            this.$store.commit('users/setSectionIndex', index);
            this.$nextTick(() => {
                this.$bvModal.show('modal-section')
            })
        },
        getSection: function (index) {
            this.$store.commit('users/setSectionIndex', index);
            this.$nextTick(() => {
                this.$bvModal.show('modal-section')
            })
        },
    }


}


</script>
