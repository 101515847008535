//import $ from 'jquery'
//import {ConfJson} from "@/main";

//import {ConfJson} from "@/main";


let WS_ADDR = "localhost:11024"

const state = {
    all_lines: []
}

/*const mutations = {


}*/
const actions = {
    getEventLines(context) {
        //let myThis = this
        //console.log(state)
        var webSocket = new WebSocket('ws://' + WS_ADDR + '/websocket_lines');
        //webSocket.send("Hello serveur...");

        webSocket.onerror = function (event) {
            console.log(event)
        };

        webSocket.onopen = function (event) {
            console.log(event)
        };

        webSocket.onmessage = function (event) {
            let myEvent = JSON.parse(event.data)
            switch (myEvent.command) {
                case "ping":
                    webSocket.send("pong")
                    console.log("PING");//send pong
                    break;
                case "key":
                    console.log("KEY")
                    context.commit('key/getCallerList', null, { root: true })
                    break;
                case "line":
                    //update/set the color of the line
                    break;
                case "live":
                    //get/close the stream
                    break;


            }

        };
        webSocket.onclose = function (event) {
            console.log(event)
        }


    },
}
export default {
    namespaced: true,
    state,
    //mutations,
    actions
}
/*
function getCallerList(state) { //Ajouter le start puis retour des events en cas de changement // Puis time OUT

    $.getJSON(ConfJson.ines_rtc_ws.service_url + "/controller?", {
        "fnct": "get_caller_list",
        "brand": localStorage.getItem("BrandName"),
        "token": localStorage.getItem("token"),
    }).done(function (data) {
        state.all_keys = data
    }).fail(function (e) {
        console.log(e)
    }).always(function () {
        //setTimeout(getUpdatedKeys(), 1000)
    });
}*/
