import $ from 'jquery'
import {ConfJson} from "@/main"
//import router from "@/router";
//import i18n from '@/i18n'



const state = {
    send: false,
    time_unit_nb:1,
    time_unit_value:3600,
    new_key: {
        "fnct": '',
        "brand": localStorage.getItem("BrandName"),
        "token": localStorage.getItem("token"),
        "dest": '',
        "name": '',
        "year": '',
        "video": 'true',
        "duration": 0,
    },
    all_keys:[]
}

const mutations = {

    newKey(state) {

        if (state.send)
            return
        state.send = true
        //state.show = true
        if (state.new_key.dest.length === 0) {
            state.new_key.fnct = "get_key";
        } else if (ValidateEmail(state.new_key.dest) || state.new_key.dest === "pb") {
            state.new_key.fnct = "send_mail";
        } else if (ValidatePhoneNumber(state.new_key.dest) && !ConfJson.options.demo){
            state.new_key.fnct = "send_sms";
        } else {
            alert("Le format utilisé n'est pas conforme (Pour l'email ou le numéro de téléphone (Norme E.164, ex:+33712345678)) ou vous êtes en mode démo et vous voulez tester une fonction payante")
            return
        }
        state.new_key.duration = state.time_unit_nb * state.time_unit_value;
        $.get(ConfJson.ines_rtc_ws.service_url + "/controller?", state.new_key).done(function () {

        }).fail(function (e) {
            console.log(e)
        }).always(function () {
            state.send = false;
            state.new_key.fnct = "";
            state.new_key.dest = "";
            state.new_key.name = "";
            state.new_key.year = "";
            state.new_key.video = "";
            state.new_key.duration = 0;
        });
    },
    getCallerList(state) {
        getCallerList(state)
    }, /*getUpdatedKeys(state) {
        getUpdatedKeys(state)
    },*/ deleteKey(_,obj) {
        $.post(ConfJson.ines_rtc_ws.service_url + "/controller?", {...{
                "fnct": "delete_key",
                "brand": localStorage.getItem("BrandName"),
                "token": localStorage.getItem("token"),
            }, ...obj}).done(function () {
        }).fail(function (e) {
            console.log(e)
        }).always(function () {});
    }
}
export default {
    namespaced: true,
    state,
    mutations
}

function ValidateEmail(mail) {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        return (true)
    }
    return (false)
}

function ValidatePhoneNumber(phoneNumber) {
    if (/^\+?[1-9]\d{1,14}$/.test(phoneNumber)) {
        return (true)
    }
    return (false)
}

function getCallerList(state) { //Ajouter le start puis retour des events en cas de changement // Puis time OUT

    $.getJSON(ConfJson.ines_rtc_ws.service_url + "/controller?", {
        "fnct": "get_caller_list",
        "brand": localStorage.getItem("BrandName"),
        "token": localStorage.getItem("token"),
    }).done(function (data) {
        state.all_keys = data
    }).fail(function (e) {
        console.log(e)
    }).always(function () {
        //setTimeout(getUpdatedKeys(), 1000)
    });
}

/*
function getUpdatedKeys(state) { //Ajouter le start puis retour des events en cas de changement // Puis time OUT
    $.getJSON(ConfJson.ines_rtc_ws.service_url + "/controller?", {
        "fnct": "get_updated_keys",
        "brand": localStorage.getItem("BrandName"),
        "token": localStorage.getItem("token"),
    }).done(function (data) {
        if (data.updated) {
            console.log("######## --- KEYS --- ########");
            getCallerList(state);
        }
        getUpdatedKeys(state);
    }).fail(function (e) {
        console.log(e)
        localStorage.removeItem('token')
        localStorage.removeItem('last_call');
        router.go()
/!*        if (e.status === 500 ||e.status === 401) {
        }*!/
    }).always(function () {

    });

}*/
