<template>
    <b-modal id="modal-user" size="xl">
        <template v-slot:modal-title>{{ $t('modal_user.title') }}</template>
        <div class="flex-row d-flex ">
            <div class="w-50 p-1">
                <b-form @submit="insupdUser">
                    <b-form-group v-if="user.pk>0"
                                  class="mb-1"
                                  v-bind:label="$t('modal_user.id')"
                    >
                        <b-form-input
                            v-model="user.pk"
                            disabled
                        />
                    </b-form-group>
                    <b-form-group
                        class="mb-2"
                        v-bind:label="$t('modal_user.user')"
                    >
                        <b-form-input
                            v-model="user.uname"
                            required
                            v-bind:placeholder="$t('modal_user.user_empty')"
                        />
                    </b-form-group>
                    <b-form-group
                        v-if="user.pk>0"
                        class="mb-2"
                    >
                        <b-form-checkbox-group>
                            <b-form-checkbox
                                v-model="user.reset_password">
                                {{ $t('modal_user.reset_pwd') }}
                            </b-form-checkbox>
                        </b-form-checkbox-group>
                    </b-form-group>
                    <b-form-group
                        class="mb-1"
                        v-bind:label="$t('modal_user.last_name')"
                    >
                        <b-form-input
                            v-model="user.last_name"
                            v-bind:placeholder="$t('modal_user.last_name_empty')"
                        />
                    </b-form-group>
                    <b-form-group
                        class="mb-1"
                        v-bind:label="$t('modal_user.first_name')"
                    >
                        <b-form-input
                            v-model="user.first_name"
                            v-bind:placeholder="$t('modal_user.first_name_empty')"
                        />
                    </b-form-group>
                    <b-form-group
                        class="mb-1"
                        v-bind:label="$t('modal_user.begin_date')"
                    >
                        <b-form-input
                            v-model="user.begin_date"
                            v-bind:placeholder="$t('modal_user.begin_date_empty')"
                        />
                    </b-form-group>
                    <b-form-group
                        class="mb-1"
                        v-bind:label="$t('modal_user.end_date')"
                    >
                        <b-form-input
                            v-model="user.end_date"
                            v-bind:placeholder="$t('modal_user.end_date_empty')"
                        />
                    </b-form-group>

                </b-form>
            </div>
            <div class="w-50 p-1">
                <b-card no-body>
                    <b-card-header class="d-flex">

                        <h5 class="d-inline-block m-auto">
                            {{ $t('modal_user.brand') }}
                        </h5>
                        </b-card-header>


                    <b-list-group-item v-for="(onebrand, index) in brand" :key="index">

                        <div class="d-flex my-auto">
                            <b-form-checkbox v-model="onebrand.checked">
                            </b-form-checkbox>

                            {{ onebrand.pk }}
                            -
                            {{ onebrand.label }}

                        </div>

                    </b-list-group-item>

                </b-card>

            </div>
            <div class="w-50 p-1">
                <b-card no-body>
                    <b-card-header class="d-flex">
                        <h5 class="d-inline-blox m-auto">
                            {{ $t('modal_user.user_group') }}
                        </h5>
                    </b-card-header>
                    <b-list-group-item v-for="(userGroup, index) in userGroupList" :key="index">

                        <div class="d-flex my-auto">
                            <b-form-checkbox v-model="userGroup.checked">
                            </b-form-checkbox>

                            {{ userGroup.pk }}
                            -
                            {{ userGroup.label }}

                        </div>
                    </b-list-group-item>
                </b-card>
            </div>
        </div>
        <template #modal-footer>
            <div class="w-100 d-flex justify-content-between">
                <div>
                    <b-button
                        variant="danger"
                        v-if="user.pk>0"
                        @click="delUser"

                    >
                        <b-icon class="align-self-center" icon="trash" font-scale="1.5"></b-icon>
                    </b-button>
                </div>
                <b-button @click="duplicateUser" variant="info" v-if="user.pk>0">
                    <b-icon icon="files"></b-icon>
                </b-button>
                <b-button
                    @click="insupdUser"
                    variant="success"

                >
                    <b-icon class="align-self-center" icon="check2" font-scale="1.5"></b-icon>
                </b-button>
            </div>

        </template>
    </b-modal>

</template>

<script>

import i18n from "@/i18n";

export default {
    name: "ModalUser",
    components: {

    },
    data() {
        return {}
    },
    computed: {
        user() {
            return this.$store.state.users.user
        },
        brand() {
            return this.$store.state.users.brand
        },
        userGroupList() {
            return this.$store.state.users.userGroupList
        }

    },
    methods: {
        insupdUser(evt) {
            evt.preventDefault();
            this.$store.commit('users/insupdUser');
            this.$nextTick(() => {
                this.$bvModal.hide('modal-user')
            })

        },

        delUser(evt) {
            evt.preventDefault();
            this.$store.commit('users/delUser');
            this.$nextTick(() => {
                this.$bvModal.hide('modal-user')
            })

        },
        duplicateUser: function () {
            var newName = prompt(i18n.t('modal_user.duplicate_question'), '' );
            if (newName == null || newName === "") {
                return
            } else {
                this.user.pk = 0;
                this.user.uname = newName
                console.log(newName)
                this.$store.commit('users/insupdUser');
                this.$nextTick(() => {
                    this.$bvModal.hide('modal-user')
                })
            }
        },
    }


}

</script>