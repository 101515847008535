<template>
    <div class="ml-3">
        <div class="d-flex">
            <div v-if="showChildren" @click="toggleChildren"><b-icon icon="dash-square"></b-icon></div>
            <div v-else @click="toggleChildren"><b-icon icon="plus-square"></b-icon></div>

            <div class="ml-2">{{ label }}</div>

        </div>
        <div v-if="showChildren">
            <sections
                    v-for="(node, index) in nodes" :key="index"
                    :nodes="node.nodes"
                    :label="node.label"
            >
            </sections>
        </div>

    </div>
</template>

<script>
    export default {
        name: "sections",
        props: [ 'label', 'nodes' ],
        data() {
            return { showChildren: false }
        },
        methods: {
            toggleChildren() {
                this.showChildren = !this.showChildren;
            }
        }
    }
</script>

<style scoped>

</style>