import $ from 'jquery'
import {ConfJson} from "@/main"
import router from "@/router";

const state = {

}

const mutations = {
    checkLicence(_,page) {
        $.post(ConfJson.ines_rtc_ws.service_url + '/controller',  {
            "fnct": "check_licence",
            "brand": localStorage.getItem("BrandName"),
            "token": localStorage.getItem("token"),
            "data": localStorage.getItem("licence")
        }).done(function () {
            let lic = JSON.parse( localStorage.getItem("licence"))
            for (let i = 0; i < lic.length;i++) {
                if (page === "rtc" && lic[i].Label === "nb_line" && lic[i].Value > 0) {
                    return
                }
                if (page === "solo" && lic[i].Label === "nb_solo" && lic[i].Value  > 0) {
                    return
                }
                if (page === "speaker" && lic[i].Label === "nb_speaker" && lic[i].Value  > 0) {
                    return
                }
            }
            localStorage.removeItem('route')
            localStorage.removeItem('token');
            localStorage.removeItem('last_call');
            router.go()
        }).fail(function(e) {
            console.log(e);
            localStorage.removeItem('route')
            localStorage.removeItem('token');
            localStorage.removeItem('last_call');
            router.go()
        });
    }

}
export default {
    namespaced: true,
    state,
    mutations
}