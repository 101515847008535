import $ from 'jquery'
import {ConfJson} from "@/main"

/*
color_off: "primary"
color_on: "success"
conference: false
default_state: true
diffusion: false
global: true
pk: 24
title: "A"
type: 0
* */
const state = {
    //index_modal:0,
    direction0:[],//INPUT
    direction1:[],//OUTPUT
    direction2:[],//OUTPUT PER LINE
    button:{},
    all_button_global: []
}

const mutations = {

    insupdButton(state, index) {
        let obj = {}
        if (index < 0 || index >= state.all_grid.length) {
            obj = {
                "pk": 0,
                "label": "",
                "url_ws": ""
            }
        } else {
            obj = state.all_grid[index]
        }
        $.post(ConfJson.ines_rtc_ws.service_url + "/controller?", {
            "fnct": "insupd_grid",
            "brand": localStorage.getItem("BrandName"),
            "token": localStorage.getItem("token"),
            "data": JSON.stringify(obj),
        }).done(function () {
            getButtonGlobal(state);
        }).fail(function (e) {
            console.log(e)
        }).always(function () {

        });
    }, getButtonGlobal(state) {
        getButtonGlobal(state);
    }, getButton(state, payload) {
        state.button = JSON.parse(JSON.stringify(state.all_button_global[payload.index]))
        getMedia(state.button.pk)
    }/*, getButtonGlobal(state) {
        getButtonGlobal(state);
    }*/, deleteButton(state, index) {
        $.post(ConfJson.ines_rtc_ws.service_url + "/controller?", {
            "fnct": "del_grid",
            "brand": localStorage.getItem("BrandName"),
            "token": localStorage.getItem("token"),
            "data": JSON.stringify({"pk": state.all_grid[index].pk}),
        }).done(function () {
            getButtonGlobal(state);
        }).fail(function (e) {
            console.log(e)
        }).always(function () {
        });
    }, insMedia(state, payload) {
        let media = {
            pk:0,
            fk_tb_button:state.button.pk,
            in_out_type:'default',
            codec:'',
            value:'',
            media_type:0,
            fk_tb_grid:payload.fk_tb_grid,
            direction:payload.direction,
            fk_tb_line:0
        };
        insupdMedia(state, media)
    }, testMedia(state, payload) {
        alert("TEST MEDIAS ")
        console.log(state)
        console.log(payload)
    }, deleteMedia(state, payload) {
        let pk = 0;
        switch (payload.direction) {
            case 0:
                pk = state.direction0[payload.index].pk;
                break;
            case 1:
                pk = state.direction1[payload.index].pk;
                break;
            case 2:
                pk = state.direction2[payload.index].pk;
                break;
        }
        $.post(ConfJson.ines_rtc_ws.service_url + "/controller?", {
            "fnct": "del_media",
            "brand": localStorage.getItem("BrandName"),
            "token": localStorage.getItem("token"),
            "data": JSON.stringify({"pk": pk}),
        }).done(function () {
            getMedia(state.button.pk)
        }).fail(function (e) {
            console.log(e)
        }).always(function () {
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations
}
/*
*   BUTTON PART
* */
function getButtonGlobal(state) {

    $.getJSON(ConfJson.ines_rtc_ws.service_url + "/controller?", {
        "fnct": "get_button_global",
        "token": localStorage.getItem("token"),
        "brand": localStorage.getItem("BrandName"),
    }).done(function (data) {
        console.log(data);
        if (data != null && data.button_global != null && data.button_global.length > 0) {
            state.all_button_global = data.button_global;
        }
    }).fail(function (e) {
        console.log(e)
    }).always(function () {

    });
}

function getMedia(fk_button) {
    $.getJSON(ConfJson.ines_rtc_ws.service_url + "/controller?", {
        "fnct": "get_media",
        "token": localStorage.getItem("token"),
        "brand": localStorage.getItem("BrandName"),
        "fk_tb_button": "" + fk_button,
    }).done(function (data) {
        console.log(data)
        state.direction0 = [];
        state.direction1 = [];
        state.direction2 = [];
        if (data != null && data.media != null && data.media.length > 0) {
            for (let i = 0; i < data.media.length; i++) {
               switch (data.media[i].direction) {
                   case 0:
                       state.direction0.push(data.media[i])
                       break;
                   case 1:
                       state.direction1.push(data.media[i])
                       break;
                   case 2:
                       state.direction2.push(data.media[i])
                       break;
                   default:
                        console.log("fail to get correction direction for media")
               }
            }
        }

    }).fail(function (e) {
        console.log(e)
    }).always(function () {

    });
}
function insupdMedia(state, obj) {

    $.post(ConfJson.ines_rtc_ws.service_url + "/controller?", {
        "fnct": "insupd_media",
        "brand": localStorage.getItem("BrandName"),
        "token": localStorage.getItem("token"),
        "data": JSON.stringify(obj),
    }).done(function (data) {
        console.log(data)
        if (obj.pk == 0)
            getMedia(state.button.pk)
    }).fail(function (e) {
        console.log(e)
    }).always(function () {

    });
}


