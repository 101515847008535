<template>
    <div class="hello p-2" >
        <div class="d-flex justify-content-between">
            <div>{{ version }}</div>
            <img src="@/assets/ines.png" alt="">
            <!--  <div class="d-flex justify-content-center">-->
            <div class="d-flex">
                <b-form-select class="w-auto align-self-center mr-2" v-model="$i18n.locale"
                               :options="$i18n.availableLocales"
                               @change="changeLocale">
                </b-form-select>
                <b-button variant="info"  size="" to="/" class="d-flex align-self-center mr-2 "> <b-icon icon="house" font-scale="1.8"></b-icon></b-button>
                <b-button variant="danger" class="d-flex align-self-center">
                    <b-icon icon="box-arrow-right" font-scale="1.8"></b-icon>
                </b-button>

            </div>

        </div>
    </div>
</template>

<script>
import {version} from '@/main'

export default {
    name: 'Header',
    props: {},
    data: function () {
        return {
            version: version,


        }
    },
    computed: {

    },
    methods: {
        changeLocale: function () {
            localStorage.setItem('locale', this.$i18n.locale)
        }
    }
}
</script>


<style>

</style>
