<template>
    <div class="">
        <Loading v-if="loading"/>
        <div v-else class="p-2">
            <b-card class="mx-auto bg-light mt-4 p-3" style="max-width: 550px">
                <div class="d-flex justify-content-between">
                <div>{{version}}</div>
                <b-form-select class="w-auto" v-model="$i18n.locale" :options="$i18n.availableLocales"
                               @change="changeLocale">
                </b-form-select>
            </div>
                <div class="text-center mb-3">
                    <div >
                        <img alt="ines logo" src="@/assets/ines.png">
                    </div>
                    <h2 class="mb-3">
                        Acceuil
                    </h2>
                    <h4 class="mb-3">
                        Veuillez choisir une application ci-dessous:
                    </h4>
                    <div >
                        <b-button variant="outline-primary" size="lg" to="rtc" class="w-50 m-1">Ines-Screen</b-button>
                        <b-button variant="outline-primary" size="lg" to="speaker" class="w-50 m-1">Ines-Speaker</b-button>
                        <b-button variant="outline-primary" size="lg" to="solo" class="w-50 m-1">Ines-Solo</b-button>
                        <b-button variant="outline-primary" size="lg" to="standard" class="w-50 m-1">Ines-Standard</b-button>
                        <b-button variant="outline-primary" size="lg" to="standard" class="w-50 m-1">Ines-Bluetooth</b-button>
                        <b-button variant="outline-primary" size="lg" to="standard" class="w-50 m-1">Ines-Games</b-button>
                        <b-button variant="outline-primary" size="lg" to="admin" class="w-50 m-1">Administration</b-button>
                        <b-button variant="outline-primary" size="lg" to="admin" class="w-50 m-1">Statistiques</b-button>
                        <b-button variant="outline-danger" size="lg" @click="delogMe" class="w-50 m-1">Déconnexion</b-button>

                    </div>
                </div>



            <!--    <template v-slot:modal-footer>
                  {{version}}
                </template>-->
            </b-card>
        </div>

    </div>

</template>

<script>
    //import MixinConf from "@/main";
    import {ConfJson, version} from "@/main"
    import Loading from "@/components/Loading"
    //import router from "@/router";

    export default {
        //mixins:[MixinConf],
        name: "Home",
        metaInfo: {
            title: 'Home',
            titleTemplate: '%s - Ines',
        },
        created: function () {
            let MyComponent = this;
            let myInterval = setInterval(function () {
                if (ConfJson != null) {
                    MyComponent.loading = false;
                    clearInterval(myInterval)
                }
            }, 100);
        },
        data: function () {
            return {
                title: "Se connecter",
                version: version,
                loading: true
            }

        },
        computed: {
            login() {
                return this.$store.state.login
            },
            authentication() {
                return this.$store.state.login.authentication
            }
        },
        props: {
            //version: "test"
        },
        components: {Loading},
        methods: {
            onSubmit: function (evt) {
                evt.preventDefault();
                if (!this.login.show) {
                    this.$store.commit('login/getBrandForConnect')
                } else {
                    this.$store.commit('login/authentication')
                }
            },
            changeLocale: function () {
                localStorage.setItem('locale', this.$i18n.locale)
            },
            delogMe:function (evt) {
                evt.preventDefault();
                this.$store.commit('login/delogMe')
            }
        }
    }
</script>

<style scoped>

</style>

<!--
<i18n>
    {
    "en": {
    "hello": "Hello i18n in SFC2!"
    },
    "fr": {

    }
    }
</i18n>-->
