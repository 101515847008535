<template>
    <b-modal id="modal-pool">
        <template v-slot:modal-title>{{ $t('modal_pool.title') }}</template>
        <b-form @submit="insupdPool">
            <b-form-group v-if="pool.pk>0"
                          class="mb-1"
                          v-bind:label="$t('modal_pool.id')"
            >
                <b-form-input
                    v-model="pool.pk"
                    disabled
                />

            </b-form-group>
            {{ $t('modal_pool.label') }}
            <b-form-group v-bind:label="$t('modal_pool.label')"
                          class="mb-1"
            >
                <b-form-input
                    v-model="pool.label"
                    required
                    v-bind:placeholder="$t('modal_pool.label_empty')"
                />

            </b-form-group>
        </b-form>
        <template #modal-footer>
            <div class="w-100 d-flex justify-content-between">
                <div>
                    <b-button
                        variant="danger"
                        v-if="pool.pk>0"
                        @click="delPool"

                    >
                        <b-icon class="align-self-center" icon="trash" font-scale="1.5"></b-icon>
                    </b-button>
                </div>
                <b-button @click="duplicatePool" variant="info" v-if="pool.pk>0">
                    <b-icon icon="files"></b-icon>
                </b-button>
                <b-button
                    @click="insupdPool"
                    variant="success"

                >
                    <b-icon class="align-self-center" icon="check2" font-scale="1.5"></b-icon>
                </b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>

import i18n from "@/i18n";


export default {
    name: "ModalPool",
    data() {
        return {}
    },
    computed: {
        pool() {
            return this.$store.state.users.currentPool
        },
    },
    methods: {
        insupdPool(evt) {
            evt.preventDefault();
            this.$store.commit('users/insupdPool');
            this.$nextTick(() => {
                this.$bvModal.hide('modal-pool')
            })

        },

        delPool(evt) {
            evt.preventDefault();
            this.$store.commit('users/delPool');
            this.$nextTick(() => {
                this.$bvModal.hide('modal-pool')
            })

        },
        duplicatePool: function () {
            var newName = prompt(i18n.t('modal_brand.duplicate_question'), '');
            if (newName == null || newName === "") {
                return
            } else {
                this.pool.pk = 0;
                this.pool.label = newName
                console.log(newName)
                this.$store.commit('users/insupdPool');
                this.$nextTick(() => {
                    this.$bvModal.hide('modal-pool')
                })
            }
        },


    }


}

</script>
