import $ from 'jquery'
import {ConfJson} from "@/main"
//import router from "@/router";
//import i18n from '@/i18n'



const state = {
    all_lines:[]
}

const mutations = {
    getAllLines(state) {
        getAllLines(state)
    }

}
export default {
    namespaced: true,
    state,
    mutations
}
function getAllLines(state) {

    $.getJSON(ConfJson.ines_rtc_ws.service_url + "/controller?", {
        "fnct": "get_all_lines",
        "brand": localStorage.getItem("BrandName"),
        "token": localStorage.getItem("token"),
    }).done(function (data) {
        console.log(data)
        alert(data.length)
        state.all_lines = data
    }).fail(function (e) {
        console.log(e)
    }).always(function () {
    });
}