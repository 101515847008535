<template>
    <div class="home">
        <Loading v-if="loading"/>

        <div v-else class="vh-100">
            <div class="vh-100  p-1">
                <b-card no-body class="bg-light w-100">
                    <b-card-body class="p-2 justify-content-between d-flex">
                        <div class="d-flex">
                            <img alt="ines-rtc logo" class="" src="@/assets/ace.png">
                            <div class="ml-2">
                                <div>
                                    {{accountName}}
                                </div>
                                <div>
                                    {{version}}
                                </div>
                            </div>
                            <div class="ml-2 ">
                                <audio v-bind:src="musicUrl" controls></audio>
                            </div>
                        </div>


                        <img alt="ines-rtc logo" class="" src="@/assets/ines-solo-i.png"
                             style="position:absolute;margin-left: auto;margin-right: auto;left: 0;right: 0;text-align: center;">

                        <div class="d-flex align-self-center justify-content-end">
                            <b-form-select class="w-auto my-auto mr-2 " v-model="$i18n.locale"
                                           :options="$i18n.availableLocales"
                                           @change="changeLocale">
                            </b-form-select>

                            <b-button variant="danger" class="d-flex ml-2 p-2" v-on:click="delogMe()">
                                <b-icon icon="box-arrow-right" font-scale="1.5" class="align-self-center"/>
                            </b-button>
                        </div>
                    </b-card-body>
                </b-card>
                <div class="d-flex pt-1" style="height: calc(100vh - 104px);">
                    <div class="pl-0 pr-1  col-5 h-100">
                        <b-card no-body class="bg-light h-100">

                            <template v-slot:header>
                                <b-alert variant="danger" class="mb-2 text-center" show>
                                    <i18n path="solo.archive_time_text">
                                        {{ file.archive_time }}
                                    </i18n>

                                </b-alert>
                                <h6 class=" text-center m-0">{{$t('solo.available_registrations')}}</h6>
                            </template>
                            <perfect-scrollbar style="height: 100%">
                                <b-card-body class="p-2 ">
                                    <b-list-group class="">
                                        <b-list-group-item v-for="(curfile, index) in file.all_files" :key="index"
                                                           class="p-2 d-flex justify-content-between" style="z-index: 1">
                                            <div class="d-flex my-auto">
                                                <a v-bind:href="curfile.link" v-bind:download="curfile.name">{{curfile.name}}</a>
                                            </div>
                                            <div class="d-flex">
                                                <span class="d-flex my-auto">{{curfile.duration}} - {{curfile.time}} </span>
                                                <b-button :id="'btn-file-'+index" class="ml-3 p-1" variant="outline-info"  >
                                                    <b-icon icon="three-dots-vertical" font-scale="1"
                                                            class="my-auto"/>
                                                    <b-popover triggers="focus" :target="'btn-file-'+index" >
                                                        <b-list-group>
                                                            <b-list-group-item button v-on:click="renameFile(curfile.name)"> <b-icon icon="pencil" font-scale="0.9"
                                                                                               class="position-absolute" style="left: 5px;top: 18px"/>
                                                                {{$t('solo.menu_edit')}}</b-list-group-item>
                                                            <b-list-group-item v-bind:href="curfile.link"
                                                                               v-bind:download="curfile.name"><b-icon icon="download" font-scale="0.9"
                                                                                              class="position-absolute" style="left: 5px;top: 18px"/>
                                                                {{$t('solo.menu_down')}}</b-list-group-item>
                                                            <b-list-group-item button  v-on:click="playFile(curfile.name, curfile.link)"> <b-icon icon="play" font-scale="0.9"
                                                                                                        class="position-absolute" style="left: 5px;top: 18px"/>
                                                                {{$t('solo.menu_play')}}</b-list-group-item>
                                                            <b-list-group-item button variant="danger"  v-on:click="deleteFile(curfile.name)"><b-icon icon="trash" font-scale="0.9"
                                                                                              class="position-absolute" style="left: 5px;top: 18px"/>
                                                                {{$t('solo.menu_del')}}</b-list-group-item>
                                                        </b-list-group>
                                                    </b-popover>
                                                </b-button>
<!--                                                <b-dropdown size="sm" class="d-flex ml-3 p-0 " variant="outline-info"
                                                            right
                                                            no-caret style="z-index: 2">
                                                    <template v-slot:button-content>
                                                        <b-icon icon="three-dots-vertical" font-scale="1"
                                                                class="my-auto"/>
                                                    </template>
                                                    <b-dropdown-item-button class="position-relative text-center"
                                                                            v-on:click="renameFile(curfile.name)">
                                                        <b-icon icon="pencil" font-scale="0.9"
                                                                class="position-absolute" style="left: 5px;top: 8px"/>
                                                        {{$t('solo.menu_edit')}}
                                                    </b-dropdown-item-button>
                                                    <b-dropdown-item class="position-relative text-center"
                                                                     v-bind:href="curfile.link"
                                                                     v-bind:download="curfile.name">
                                                        <b-icon icon="download" font-scale="0.9"
                                                                class="position-absolute" style="left: 5px;top: 8px"/>
                                                        {{$t('solo.menu_down')}}
                                                    </b-dropdown-item>
                                                    <b-dropdown-item-button class="position-relative text-center"
                                                                            v-on:click="playFile(curfile.name, curfile.link)">
                                                        <b-icon icon="play" font-scale="0.9"
                                                                class="position-absolute" style="left: 5px;top: 8px"/>
                                                        {{$t('solo.menu_play')}}
                                                    </b-dropdown-item-button>
                                                    <b-dropdown-divider/>
                                                    <b-dropdown-item-button variant="danger"
                                                                            class="position-relative text-center"
                                                                            v-on:click="deleteFile(curfile.name)">
                                                        <b-icon icon="trash" font-scale="0.9"
                                                                class="position-absolute" style="left: 5px;top: 8px"/>
                                                        {{$t('solo.menu_del')}}
                                                    </b-dropdown-item-button>
                                                </b-dropdown>-->

                                                <!--                                                <b-button variant="info" class="d-flex ml-3 p-1 "
                                                                                                          v-on:click="deleteFile(curfile.name)">

                                                                                                    <b-icon icon="three-dots-vertical" font-scale="1"
                                                                                                            class="my-auto"/>
                                                                                                </b-button>-->
                                            </div>
                                        </b-list-group-item>
                                    </b-list-group>
                                </b-card-body>
                            </perfect-scrollbar>

                        </b-card>
                    </div>

                    <div class="col-7 p-0 h-100 ">
                        <b-card no-body class="bg-light h-100">
                            <perfect-scrollbar>

                                <b-card-body class="p-2">
                                    <div class="d-flex mb-3 d-flex justify-content-between">
                                        <div class="">
                                            <b-badge v-if="mySip.sipConnected" pill variant="success">
                                                {{$t('solo.sip_msg1')}}
                                            </b-badge>
                                            <b-badge v-else pill variant="warning">{{$t('solo.sip_msg2')}}</b-badge>
                                        </div>

                                        <div class="">
                                            <div class="float-right" v-if="file.write_file_status">
                                                <b-spinner class="mr-3" small variant="danger" type="grow"
                                                           label="Spinning"/>
                                                <b-badge class="" pill variant="danger">
                                                    {{$t('solo.recording_in_progress')}}
                                                </b-badge>
                                            </div>
                                            <div class="float-right" v-else>
                                                <b-badge class="" pill variant="warning">
                                                    {{$t('solo.no_recording')}}
                                                </b-badge>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="d-flex justify-content-center">
                                            <div class="col-2 d-flex text-center justify-content-center">
                                                <div>
                                                    <b-button variant="outline-secondary" @click="muteAudio">
                                                        <b-icon v-if="mySip.audio_muted" icon="mic-mute" font-scale="3"
                                                                class="align-self-center"/>
                                                        <b-icon v-else icon="mic" font-scale="3"
                                                                class="align-self-center"/>
                                                    </b-button>


                                                    <div id="my-peak-meter-in"
                                                         class="mt-2 overflow-hidden rounded border "
                                                         style="width: 5em; height: 20em;">
                                                    </div>
                                                </div>

                                            </div>
                                            <!--<canvas id="test1" style="background-color: black;"></canvas>-->
                                            <div class="col-8">

                                                <audio id="localAudio" class="d-none" controls>
                                                </audio>
                                                <audio id="remoteAudio" class="d-none" controls>
                                                </audio>
                                                <div class="w-100 d-flex justify-content-center">
                                                    <h1>{{time}}</h1>
                                                </div>
                                                <div class=" w-100 mb-3">
                                                    <b-form-input id="input-call-number" type="text"
                                                                  class="input-style-call" v-model="phoneNumber"
                                                                  v-bind:placeholder="$t('solo.phone_number')"
                                                                  autocomplete="off"/>
                                                </div>
                                                <div class="w-100 keypad mb-3 text-center position-relative"
                                                     :class="mySip.callIncoming || mySip.callOutgoing? 'overlay rounded':''">
                                                    <div v-for="(_,index) in keyForKeypad" :key="index"
                                                         style="margin-bottom: 0.5rem">
                                                        <b-button v-for="(key, index2) in keyForKeypad[index]"
                                                                  :key="index2"
                                                                  variant="outline-secondary"
                                                                  class="keypad-style"
                                                                  v-on:click="keypad(key)">{{key}}
                                                        </b-button>
                                                    </div>

                                                    <div class="position-absolute d-flex  justify-content-center  align-items-center h-100 w-100 font-weight-bold"
                                                         style="font-size: 42pt;top:0" v-if="mySip.callIncoming">
                                                        {{$t('solo.incomming_call')}}
                                                    </div>
                                                    <div class="position-absolute d-flex  justify-content-center  align-items-center h-100 w-100 font-weight-bold"
                                                         style="font-size: 42pt;top:0" v-if="mySip.callOutgoing">
                                                        {{$t('solo.outgoing_call')}}
                                                    </div>
                                                </div>

                                                <div class="w-100 d-flex justify-content-around "
                                                     v-if="mySip.callIncoming">

                                                    <b-button variant="success" class="d-flex p-3 rounded-circle"
                                                              v-on:click="anserCall()">
                                                        <b-icon icon="telephone" font-scale="4"
                                                                class="align-self-center"/>
                                                    </b-button>
                                                    <b-button variant="danger" class="d-flex p-3 rounded-circle"
                                                              v-on:click="rejectCall()">
                                                        <b-icon icon="telephone" font-scale="4"
                                                                class="align-self-center rotate"/>
                                                    </b-button>
                                                </div>
                                                <div class="w-100 d-flex justify-content-around "
                                                     v-else-if="mySip.callOutgoing">
                                                    <b-button variant="danger" class="d-flex p-3 rounded-circle"
                                                              v-on:click="cancelCall()">
                                                        <b-icon icon="telephone" font-scale="4"
                                                                class="align-self-center rotate"/>
                                                    </b-button>
                                                </div>
                                                <div class="w-100 d-flex justify-content-around "
                                                     v-else-if="mySip.onCall">
                                                    <b-button variant="danger" class="d-flex p-3 rounded-circle"
                                                              v-on:click="endCall()">
                                                        <b-icon icon="telephone" font-scale="4"
                                                                class="align-self-center rotate"/>
                                                    </b-button>
                                                </div>
                                                <div class="w-100 d-flex justify-content-around" v-else>
                                                    <b-button variant="success" class="d-flex p-3 rounded-circle"
                                                              v-on:click="makeCall()">
                                                        <b-icon icon="telephone" font-scale="4"
                                                                class="align-self-center"/>
                                                    </b-button>
                                                </div>
                                            </div>
                                            <div class="col-2 d-flex text-center justify-content-center">
                                                <div>
                                                    <b-icon icon="headphones" font-scale="3"
                                                            class="align-self-center"/>
                                                    <div id="my-peak-meter-out"
                                                         class="mt-2 overflow-hidden border rounded"
                                                         style="width: 5em; height: 20em;">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </b-card-body>
                            </perfect-scrollbar>
                        </b-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    /*
    *  IMPORT COMPONENTS
    * */
    import Loading from "@/components/Loading"
    import {ConfJson, version} from "@/main";

    /*
    *  IMPORT LIBS
    * */

    //import Aplayer from 'vue-aplayer'
    import {PerfectScrollbar} from 'vue2-perfect-scrollbar'
    import i18n from "@/i18n";

    export default {
        name: 'Solo',
        metaInfo: {
            title: 'Solo',
            titleTemplate: '%s - Ines',
        },
        created: function () {
            //console.log(ConfJson)
            let MyComponent = this;
            let myInterval = setInterval(function () {
                if (ConfJson != null) {
                    MyComponent.$store.commit('licence/checkLicence', "solo");
                    MyComponent.loading = false;
                    MyComponent.$store.commit('mySip/registerUserAgent');
                    MyComponent.$store.commit('file/getFiles');
                    MyComponent.$store.commit('file/getArchiveTime');

                    MyComponent.$store.commit('file/checkFile');
                    clearInterval(myInterval);
                }
            }, 100);
            setInterval(function () { // update set time
                //alert(MyComponent.time)
                //console.log(MyComponent.file)
                if (MyComponent.mySip.onCall) {
                    let NewTimeOnCall = new Date(Date.parse("01 Jan 1970 " + MyComponent.time + " GMT") + 1000);
                    MyComponent.time = MyComponent.secondToHourString2(NewTimeOnCall.getTime() / 1000);
                } else if (MyComponent.time !== "00:00:00") {
                    MyComponent.time = "00:00:00"
                    var newName = prompt(i18n.t('solo.new_file_name'), '');

                    if (newName == null || newName === "") {
                        return
                    } else {
                        if (newName.includes(".wav")) {
                            let newNamePart = newName.split(".")
                            newNamePart[0] = newNamePart[0].replace(/[\W_]+/g, "");
                            newName = newNamePart.join(".")
                        } else {
                            newName = newName.replace(/[\W_]+/g, "");
                        }
                        console.log(newName)
                        MyComponent.$store.commit('file/renameFile', {old_name: '', name: newName})
                    }

                }
            }, 1000);
        },
        components: {
            Loading,
            PerfectScrollbar,

        },
        data: function () {
            return {
                version: version,
                accountName: localStorage.getItem("accountName"),
                loading: true,
                keyForKeypad: [["1", "2", "3", "Del"], ["4", "5", "6", "Cls"], ["7", "8", "9", "Bis"], ["*", "0", "#", "+"]],
                time: "00:00:00",
                phoneNumber: "",
                protocol: "http",
                musicUrl: ""
            }
        },
        computed: {
            mySip() {
                return this.$store.state.mySip
            },
            file() {
                return this.$store.state.file
            },
        },
        methods: {
            changeLocale: function () {
                localStorage.setItem('locale', this.$i18n.locale)
            },
            delogMe: function () {
                this.$store.commit('login/delogMe')
            },
            makeCall: function () {
                this.$store.commit('mySip/makeCall', this.phoneNumber)
            },
            cancelCall: function () {
                this.$store.commit('mySip/cancelCall')
            },
            anserCall: function () {
                this.$store.commit('mySip/answerCall')
            },
            endCall: function () {
                this.$store.commit('mySip/endCall')
            },
            rejectCall: function () {
                this.$store.commit('mySip/rejectCall')
            },muteAudio: function () {
                this.$store.commit('mySip/muteAudio')
            }, deleteFile: function (name) {
                this.$store.commit('file/deleteFile', name)
            }, renameFile: function (name) {
                var newName = prompt(this.$t('solo.new_file_name'), name);
                if (newName == null || newName === "") {
                    return
                }
                if (newName.includes(".wav")) {
                    let newNamePart = newName.split(".");
                    newNamePart[0] = newNamePart[0].replace(/[\W_]+/g, "");
                    newName = newNamePart.join(".")
                } else {
                    newName = newName.replace(/[\W_]+/g, "");
                }
                console.log(newName);
                this.$store.commit('file/renameFile', {old_name: name, name: newName})
            }, playFile: function (name, url) {
                this.musicUrl = url
                /*                console.log(name)
                                console.log(url)*/
                /*                this.list.push({
                                    title: name,
                                        artist: localStorage.getItem("authorization_user"),
                                        src: require(url)
                                })*/
                /*                this.aplayer_music.title = name;

                                this.aplayer_music.src = url;*/
                //alert(this.aplayer_music.src);
                //Aplayer.play ()
            },
            secondToHourString2: function (second) {
                second = parseInt(second);
                let ret = "";

                if (second > 3600) {
                    ret = ret + (parseInt(second / 3600) < 10 ? "0" + parseInt(second / 3600) : parseInt(second / 3600)) + ":";
                    second = second % 3600;
                } else if (second == 3600) {
                    ret = ret + "01:";
                    second = second % 3600;
                } else {
                    ret = ret + "00:";
                }
                if (second > 60) {
                    ret = ret + (parseInt(second / 60) < 10 ? "0" + parseInt(second / 60) : parseInt(second / 60)) + ":";
                    second = second % 60;
                } else if (second == 60) {
                    ret = ret + "01:";
                    second = second % 60;
                } else {
                    ret = ret + "00:";
                }
                ret = ret + (second < 10 ? "0" + second : second);
                return ret;
            },
            keypad: function (val) {
                let newVal = this.phoneNumber;
                switch (val) {
                    case "Del":
                        newVal = newVal.slice(0, -1);
                        break;
                    case "Cls":
                        newVal = '';
                        break;
                    case "Bis":
                        if (localStorage.getItem("last_call").length > 0) {
                            newVal = localStorage.getItem("last_call")
                        }
                        break;
                    default:
                        newVal += val;
                        break;
                }
                this.phoneNumber = newVal;
            }
        }
    }

</script>

<style scoped>
    .keypad-style {
        font-size: 1.5rem;
        text-align: center;
        vertical-align: middle;
        width: 90px;
        height: 100%;
        margin-right: 0.5rem;
        padding: 1rem;
    }

    .rotate {
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -ms-transform: rotate(135deg);
        -o-transform: rotate(135deg);
    }

    .overlay {
        background-color: rgba(0, 0, 0, 0.3); /* Black background with opacity */
        z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    }

    audio:focus {
        outline: none;
    }

</style>