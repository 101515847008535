<template>
    <div>{{line.id}}</div>
</template>

<script>
    export default {
        name: "Line",
        props: ['line']
    }
</script>

<style scoped>

</style>