<template>
    <b-modal id="modal-brand" size="lg">
        <template v-slot:modal-title>{{ $t('modal_brand.title') }}</template>
        <div class="flex-row d-flex" >
            <div class="w-50 p-1">
                <b-form @submit="insupdBrand">
                    <b-form-group v-if="brand.pk>0"
                                  v-bind:label="$t('modal_brand.id')"
                                  class="mb-1"
                                  disabled
                    >
                        <b-form-input
                            v-model="brand.pk"
                        />

                    </b-form-group>
                    <b-form-group
                        v-bind:label="$t('modal_brand.label')"
                        class="mb-1"
                    >
                        <b-form-input
                            v-model="brand.label"
                            v-bind:placeholder="$t('modal_brand.label_empty')"
                        />

                    </b-form-group>

                    <b-form-group
                        v-bind:label="$t('modal_brand.logo')"
                        class="mb-1"
                    >
                        <b-form-input
                            v-model="brand.logo_path"
                        />

                    </b-form-group>

                </b-form>
            </div>
            <div class="w-50 p-1">
                <b-card no-body>
                    <b-card-header class="d-flex">

                        <h5 class="d-inline-block m-auto">
                            {{ $t('modal_brand.pool') }}
                        </h5>
                        <b-button variant="success" class="d-flex align-self-center" @click="getPoolIndex(-1)">
                            <b-icon class="align-self-center" icon="plus" font-scale="1.5"></b-icon>
                        </b-button>
                    </b-card-header>


                    <b-list-group-item v-for="(onepool, index) in pool" :key="index">

                        <div class="d-flex my-auto">
                            <b-form-checkbox v-model="onepool.checked">
                            </b-form-checkbox>

                            {{ onepool.pk }}
                            -
                            {{ onepool.label }}
                            <b-card-body class="text-right p-1">
                                <b-icon icon="pencil-square" @click="getPool(index)"></b-icon>
                            </b-card-body>
                        </div>
                    </b-list-group-item>

                </b-card>
            </div>
        </div>

        <template #modal-footer>
            <div class="w-100 d-flex justify-content-between">
                <div>
                    <b-button
                        variant="danger"
                        v-if="brand.pk>0"
                        @click="delBrand"

                    >
                        <b-icon class="align-self-center" icon="trash" font-scale="1.5"></b-icon>
                    </b-button>
                </div>
                <b-button @click="duplicateBrand" variant="info" v-if="brand.pk>0">
                    <b-icon icon="files"></b-icon>
                </b-button>
                <b-button
                    @click="insupdBrand"
                    variant="success"

                >
                    <b-icon class="align-self-center" icon="check2" font-scale="1.5"></b-icon>
                </b-button>
            </div>
            <modal-pool/>
        </template>

    </b-modal>
</template>

<script>
import ModalPool from '@/components/admin/ModalPool.vue'
import i18n from "@/i18n";


export default {
    name: "ModalBrand",
    components: {
        ModalPool,
    },
    data() {
        return {}
    },
    computed: {
        brand() {
            return this.$store.state.users.currentBrand
        },
        pool() {
            return this.$store.state.users.pool
        },
    },


    methods: {
        insupdBrand(evt) {
            evt.preventDefault();
            this.$store.commit('users/insupdBrands');
            this.$nextTick(() => {
                this.$bvModal.hide('modal-brand')
            })

        },

        getBrand: function (index) {
            this.$store.commit('users/setBrandIndex', index);
            this.$nextTick(() => {
                this.$bvModal.show('modal-brand')
            })
        },

        delBrand(evt) {
            evt.preventDefault();
            this.$store.commit('users/delBrand');
            this.$nextTick(() => {
                this.$bvModal.hide('modal-brand')
            })

        },

        getBrandIndex: function (index) {
            this.$store.commit('users/setBrandIndex', index);
            this.$nextTick(() => {
                this.$bvModal.show('modal-brand')
            })
        },
        duplicateBrand: function () {
            var newName = prompt(i18n.t('modal_pool.duplicate_question'), '');
            if (newName == null || newName === "") {
                return
            } else {
                this.brand.pk = 0;
                this.brand.label = newName
                console.log(newName)
                this.$store.commit('users/insupdBrands');
                this.$nextTick(() => {
                    this.$bvModal.hide('modal-brand')
                })
            }
        },


        getPoolIndex: function (index) {
            this.$store.commit('users/setPoolIndex', index);
            this.$nextTick(() => {
                this.$bvModal.show('modal-pool')
            })
        },


        getPool: function (index) {
            this.$store.commit('users/setPoolIndex', index);
            this.$nextTick(() => {
                this.$bvModal.show('modal-pool')
            })
        },

    }


}

</script>
