import $ from 'jquery'
import {ConfJson} from "@/main"

import router from "@/router";
//import i18n from '@/i18n'


const state = {
    write_file_status: false,
    archive_time: "",
    all_files: null
}

const mutations = {

    getFiles(state) {
        getFiles(state)
    }, checkFile(state) {
        $.getJSON(ConfJson.ines_rtc_ws.service_url + "/files_api?", {
            "fnct": "check_file",
            "token": localStorage.getItem("token"),
        }).done(function (data) {
            if (state.write_file_status != data.ok) {
                getFiles(state)
            }
            state.write_file_status = data.ok
            mutations.checkFile(state)
        }).fail(function (e) {
            //state.write_file_status = false
            localStorage.removeItem('token')
            localStorage.removeItem('last_call');
            router.go()
            console.log(e)
        }).always(function () {

        });
    }, getArchiveTime() {
        $.get(ConfJson.ines_rtc_ws.service_url + "/files_api?", {
            "fnct": "archive_time",
            //"brand": localStorage.getItem("BrandName"),
            "token": localStorage.getItem("token")
        }).done(function (data) {
            state.archive_time = data
        }).fail(function (e) {
            console.log(e)
        }).always(function () {
        });
    }, renameFile(_, object) {
        $.get(ConfJson.ines_rtc_ws.service_url + "/files_api?", {
            "fnct": "rename_file",
            //"brand": localStorage.getItem("BrandName"),
            "token": localStorage.getItem("token"),
            "old_name": object.old_name,
            "name": object.name,
        }).done(function () {
            getFiles(state)
        }).fail(function (e) {
            console.log(e)
        }).always(function () {
        });
    }, deleteFile(_, name) {
        $.get(ConfJson.ines_rtc_ws.service_url + "/files_api?", {
            "fnct": "delete_file",
            //"brand": localStorage.getItem("BrandName"),
            "token": localStorage.getItem("token"),
            "name": name,
        }).done(function () {
            getFiles(state)
        }).fail(function (e) {
            console.log(e)
        }).always(function () {
        });
    }
}
export default {
    namespaced: true,
    state,
    mutations
}

function getFiles(state) {

    $.getJSON(ConfJson.ines_rtc_ws.service_url + "/files_api?", {
        "fnct": "get_files",
        "token": localStorage.getItem("token"),
    }).done(function (data) {
        console.log(data)
        state.all_files = data
        if (state.all_files != null) {
            for (let i = 0; i < state.all_files.length; i++) {
                state.all_files[i].link = ConfJson.ines_rtc_ws.service_url + '/' + state.all_files[i].path + "?token=" + localStorage.getItem('token')
            }
        }


    }).fail(function (e) {
        console.log(e)
    }).always(function () {
        //setTimeout(getUpdatedKeys(), 1000)
    });
}


