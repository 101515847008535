import $ from 'jquery'
import {ConfJson} from '@/main'

let UserApiPath ="/users_api";//"/users"//"/users_api"

const state = {
    userList: [],
    user: {},
    brand: [],
    currentBrand: {},
    pool: [],
    currentPool: {},
    userGroupList: [],
    userGroup: {},
    section: [],
    currentSection: {},
    AllFunctionGet: [],
    AllFunctionPost: [],
}

const mutations = {
    getUserList(state) {
        GetUserList(state)
    },
    getUser(state, pk) {
        if (pk <= 0) {
            for (let i = 0; i < state.brand.length; i++) {
                state.brand[i].checked = false
            }
            state.user.pk = 0;
            state.user.uname = "";
            state.user.first_name = "";
            state.user.last_name = "";
            state.user.begin_date = "";
            state.user.end_date = "";
            state.user.brands = [];
            state.user.userGroup = [];
            return;
        }
        state.user.brands = []
        for (let i = 0; i < state.brand.length; i++) {
            if (state.brand[i].checked) {
                state.user.brands.push(state.brand[i].pk)
            }
        }
        console.log(state.user)
        $.getJSON(ConfJson.ines_rtc_ws.service_url + UserApiPath, {
            "fnct": "get_user",
            "pk": pk,
        }).done(function (data) {
            if (data !== null && data.user.length === 1) {
                state.user = data.user[0]
                getCheckedBrand(state)
                console.log(data)
            }
        })
    },
    insupdUser(state) {
        state.user.brands = []
        for (let i = 0; i < state.brand.length; i++) {
            if (state.brand[i].checked) {
                state.user.brands.push(state.brand[i].pk)
            }
        }
        console.log(state.user)
        $.post(ConfJson.ines_rtc_ws.service_url + UserApiPath, {
            "fnct": "insupd_user",
            "brand": "demo",
            "data": JSON.stringify(state.user),
        }).done(function () {
            GetUserList(state)

        }).fail(function (e) {
            console.log(e)
            if (e.status === 415) {
                alert("Probleme dans la base de donnée, aucune mise à jour n'a été effectué, error : " + e.responseText)
            } else if (e.status === 500) {
                alert("Probleme dans le serveur, les datas sont elles correctes ? error : " + e.responseText)
            } else {
                alert("Probleme grave, veuillez contacter un administratateur et regarder les logs : " + e.responseText)
            }
        })
    },
    delUser(state) {
        $.post(ConfJson.ines_rtc_ws.service_url + UserApiPath, {
            "fnct": "del_user",
            "brand": "demo",
            "data": JSON.stringify(state.user),
        }).done(function () {
            GetUserList(state)

        })
    },
    getBrand(state) {
        GetBrand(state) // get des marques
    },
    setBrandIndex(state, index) {
        if (index < 0) {
            for (let i = 0; i < state.pool.length; i++) {
                state.pool[i].checked = false
            }
            state.currentBrand = {
                pk: 0,
                label: "",
                logo_path: "",
                fk_tb_pool: [],
            }
            return;
        }
        state.currentBrand = state.brand[index]
        getCheckedPool(state)
    },

    insupdBrands(state) {
        state.currentBrand.fk_tb_pool = []
        for (let i = 0; i < state.pool.length; i++) {
            if (state.pool[i].checked) {
                state.currentBrand.fk_tb_pool.push(state.pool[i].pk)
            }
        }
        console.log(state.currentBrand)
        $.post(ConfJson.ines_rtc_ws.service_url + UserApiPath, {
            "fnct": "insupd_brand",
            "brand": "demo",
            "data": JSON.stringify(state.currentBrand)
        }).done(function () {
            GetBrand(state)
        }).fail(function (e) {
            console.log(e)
            if (e.status === 415) {
                alert("Probleme dans la base de donnée, aucune mise à jour n'a été effectué, error : " + e.responseText)
            } else if (e.status === 500) {
                alert("Probleme dans le serveur, les datas sont elles correctes ? error : " + e.responseText)
            } else {
                alert("Probleme grave, veuillez contacter un administratateur et regarder les logs : " + e.responseText)
            }

        })


    },
    delBrand(state) {
        $.post(ConfJson.ines_rtc_ws.service_url + UserApiPath, {
            "fnct": "del_brand",
            "brand": "demo",
            "data": JSON.stringify(state.currentBrand),
        }).done(function () {
            GetBrand(state)

        })
    },
    getPool(state) {
        GetPool(state)
    },
    setPoolIndex(state, index) {
        if (index < 0) {
            state.currentPool = {
                pk: 0,
                label: "",
            }
            return;
        }
        state.currentPool = state.pool[index]
    },

    insupdPool(state) {
        $.post(ConfJson.ines_rtc_ws.service_url + UserApiPath, {
            "fnct": "insupd_pool",
            "brand": "demo",
            "data": JSON.stringify(state.currentPool)
        }).done(function () {
            GetPool(state)

        }).fail(function (e) {
            console.log(e)
            if (e.status === 415) {
                alert("Probleme dans la base de donnée, aucune mise à jour n'a été effectué, error : " + e.responseText)
            } else if (e.status === 500) {
                alert("Probleme dans le serveur, les datas sont elles correctes ? error : " + e.responseText)
            } else {
                alert("Probleme grave, veuillez contacter un administratateur et regarder les logs : " + e.responseText)
            }
        })
    },
    delPool(state) {
        $.post(ConfJson.ines_rtc_ws.service_url + UserApiPath, {
            "fnct": "del_pool",
            "brand": "demo",
            "data": JSON.stringify(state.currentPool),
        }).done(function () {
            GetPool(state)

        })
    },

    getUserGroupList(state) {
        GetUserGroupList(state)
    },

    getUserGroup(state, pk) {
        if (pk <= 0) {
            state.userGroup.pk = 0;
            state.userGroup.label = "";

            return;
        }
        console.log(state.userGroup)
        $.getJSON(ConfJson.ines_rtc_ws.service_url + UserApiPath, {
            "fnct": "get_user_group",
            "pk": pk,
        }).done(function (data) {

            if (data !== null && data.user_group.length === 1) {
                console.log(data);
                state.userGroup = data.user_group[0]
            }
        })

    },

    insupdUserGroup(state) {
        $.post(ConfJson.ines_rtc_ws.service_url + UserApiPath, {
            "fnct": "insupd_user_group",
            "brand": "demo",
            "data": JSON.stringify(state.userGroup)
        }).done(function () {
            GetUserGroupList(state)
        }).fail(function (e) {
            console.log(e)
            if (e.status === 415) {
                alert("Probleme dans la base de donnée, aucune mise à jour n'a été effectué, error : " + e.responseText)
            } else if (e.status === 500) {
                alert("Probleme dans le serveur, les datas sont elles correctes ? error : " + e.responseText)
            } else {
                alert("Probleme grave, veuillez contacter un administratateur et regarder les logs : " + e.responseText)
            }

        })


    },
    delUserGroup(state) {
        $.post(ConfJson.ines_rtc_ws.service_url + UserApiPath, {
            "fnct": "del_user_group",
            "brand": "demo",
            "data": JSON.stringify(state.userGroup),
        }).done(function () {
            GetUserGroupList(state)

        })
    },

    getSection(state, obj) {
        GetSection(state, obj.fkTbSection, obj.index)
    },

    setSectionIndex(state, index) { // TODO : A FAIRE
        if (index < 0) {
            state.currentSection = {
                pk: 0,
                label: "",
                fk_tb_section: 0,
            }
            return;
        } else {
            //LE 0 D'ici est le meme que :
            //TODO : ENLEVER LE 0 POUR GERER LES ENFANTS
            state.currentSection = state.section[0][index]
        }

    },

    insupdSection(state, obj) {
        console.log("###CURRENT SECTION###")
        /*
                console.log(state.currentSection)
        */
        $.post(ConfJson.ines_rtc_ws.service_url + UserApiPath, {
            "fnct": "insupd_section",
            "brand": "demo",
            "data": JSON.stringify(state.currentSection)
        }).done(function () {
            GetSection(state, obj.fkTbSection, obj.index)

        }).fail(function (e) {
            console.log(e)
            if (e.status === 415) {
                alert("Probleme dans la base de donnée, aucune mise à jour n'a été effectué, error : " + e.responseText)
            } else if (e.status === 500) {
                alert("Probleme dans le serveur, les datas sont elles correctes ? error : " + e.responseText)
            } else {
                alert("Probleme grave, veuillez contacter un administratateur et regarder les logs : " + e.responseText)
            }
        })
    },

    delSection(state, obj) {
        $.post(ConfJson.ines_rtc_ws.service_url + UserApiPath, {
            "fnct": "del_section",
            "brand": "demo",
            "data": JSON.stringify(state.currentSection),
        }).done(function () {
            GetSection(state, obj.fkTbSection, obj.index)

        })
    },
    getFunction(state, type) {
        GetFunction(state, type)
    },

    insupdFunction(state, obj) {
        //TODO : GET THE INDEX
        console.log(obj)
        $.post(ConfJson.ines_rtc_ws.service_url + UserApiPath, {
            "fnct": "insupd_function",
            "brand": "demo",
            "data": JSON.stringify(obj)
        }).done(function () {
            GetFunction(state, obj.type)


        }).fail(function (e) {
            console.log(e)
            if (e.status === 415) {
                alert("Probleme dans la base de donnée, aucune mise à jour n'a été effectué, error : " + e.responseText)
            } else if (e.status === 500) {
                alert("Probleme dans le serveur, les datas sont elles correctes ? error : " + e.responseText)
            } else {
                alert("Probleme grave, veuillez contacter un administratateur et regarder les logs : " + e.responseText)
            }
        })
    },
    delFunction(state, obj) {
        let func = {}
        switch (obj.type) {
            case "get":
                func = state.AllFunctionGet[obj.index]
                break
            case "post":
                func = state.AllFunctionPost[obj.index]
                break
        }

        $.post(ConfJson.ines_rtc_ws.service_url + UserApiPath, {
            "fnct": "del_function",
            "brand": "demo",
            "data": JSON.stringify({pk:func.pk}),
        }).done(function () {
            GetFunction(state,obj.type)

        })
    },
}

export default {
    namespaced: true,
    state,
    mutations

}

function GetUserList(state) {
    $.getJSON(ConfJson.ines_rtc_ws.service_url + UserApiPath, {
        "fnct": "get_user_list",
    }).done(function (data) {
        if (state.user.pk) {
            getCheckedBrand(state)
        }
        state.userList = data.user_list
        console.log(data)
    })
}

function GetBrand(state) {
    $.getJSON(ConfJson.ines_rtc_ws.service_url + UserApiPath, {
        "fnct": "get_brand",
    }).done(function (data) {
        state.brand = data.brand
        console.log(data)
    })
}

function GetPool(state) {
    $.getJSON(ConfJson.ines_rtc_ws.service_url + UserApiPath, {
        "fnct": "get_pool",
    }).done(function (data) {
        if (state.currentBrand.pk) {
            getCheckedPool(state)
        }
        state.pool = data.pool
        console.log(data)
    })
}

function GetSection(state, fkTbSection, index) {
    $.getJSON(ConfJson.ines_rtc_ws.service_url + UserApiPath, {
        "fnct": "get_section",
        "fk_tb_section": fkTbSection,
    }).done(function (data) {
        /*
        * ON A ICI Un tableau de tableau de section
        * l'index -1 ajoute un nouveau tableau dans le tableau
        * les sections sans parent (racine) sont a l'index 0
        * a chaque nouveau get, on ajoute un nouveau tableau de section dans le tableau
        * */
        switch (index) {
            case -1:
                state.section.push(data.sections)
                break
            default:
                state.section[index] = data.sections
                break
        }
        console.log(state.section)
    })
}

function GetFunction(state, type) {
    $.getJSON(ConfJson.ines_rtc_ws.service_url + UserApiPath, {
        "fnct": "get_function",
        "type": type,
    }).done(function (data) {

        switch (type) {
            case "get":
                state.AllFunctionGet = data.functions
                break
            case "post":
                state.AllFunctionPost = data.functions
                break
        }
        //state.function = data.function // TODO : separer les fonctions GET et POST (Car le deuxieme appel va ecraser le premier)
        console.log(data)
    })
}

function GetUserGroupList(state) {
    $.getJSON(ConfJson.ines_rtc_ws.service_url + UserApiPath, {
        "fnct": "get_user_group_list",
    }).done(function (data) {
        state.userGroupList = data.user_group_list
        console.log(data)
    })
}

function getCheckedPool(state) {
    for (let i = 0; i < state.pool.length; i++) {
        state.pool[i].checked = false
        for (let j = 0; j < state.currentBrand.fk_tb_pool.length; j++) {
            if (state.pool[i].pk === state.currentBrand.fk_tb_pool[j]) {
                state.pool[i].checked = true;
                break;
            }
        }
    }
    console.log(state.pool) // pour voir si les data sont bien mises a jour
}


function getCheckedBrand(state) {
    for (let i = 0; i < state.brand.length; i++) {
        state.brand[i].checked = false
        for (let j = 0; j < state.user.brands.length; j++) {
            if (state.brand[i].pk === state.user.brands[j]) {
                state.brand[i].checked = true;
                break;
            }
        }
    }
    console.log(state.brand) // pour voir si les data sont bien mises a jour
}

