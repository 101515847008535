<template>
    <div>
        <perfect-scrollbar class="scroll-size flex-fill d-flex border-right border-bottom border-left rounded-bottom">
            <div class="listener-size">
                <div class="d-flex ">
                    <div class="p-1 w-50">
                        <b-card no-body class=" p-1 " style="background-color: rgba(0, 0, 0, 0.03)">
                            <b-card-title class="mb-1 title-style text-center">Informations</b-card-title>
                            <b-card-text>
                                <b-form class="d-flex flex-wrap">
                                    <b-form-group class="w-50 px-1 mb-1">
                                        <label class="mb-0 text-black-50">Prénom:</label>
                                        <b-form-input size="lg" placeholder="Prénom"/>
                                    </b-form-group>
                                    <b-form-group class=" w-50  px-1  mb-1">
                                        <label class="mb-0 text-black-50">Nom:</label>
                                        <b-form-input size="lg" placeholder="Nom"/>
                                    </b-form-group>
                                    <!-- -->
                                    <b-form-group class="w-50 p-1 mb-1">
                                        <label class="mb-0 text-black-50">Date de Naissance:</label>
                                        <b-form-input size="lg" placeholder="Date de Naissance"/>
                                    </b-form-group>
                                    <b-form-group class=" w-50  px-1  mb-1">
                                        <label class="mb-0 text-black-50">Age:</label>
                                        <b-form-input size="lg" placeholder="Age"/>
                                    </b-form-group>
                                    <!-- -->
                                    <div class="w-50 d-flex px-1 mb-1">
                                        <b-form-group class="w-50 px-1 mb-1">
                                            <label class="mb-0 text-black-50 w-100">Genre:</label>
                                            <b-form-radio-group buttons id="radio-group-2"
                                                                button-variant="outline-secondary" v-model="selected"
                                                                name="radio-sub-component">
                                                <b-form-radio class="p-2" value="first">
                                                    <svg height="16pt" viewBox="0 0 384 384" width="16pt">
                                                        <path d="m383.792969 13.9375c-.175781-1.378906-.480469-2.707031-.984375-3.953125-.015625-.03125-.015625-.074219-.023438-.113281 0-.007813-.007812-.015625-.015625-.023438-.554687-1.3125-1.3125-2.503906-2.167969-3.609375-.210937-.261719-.417968-.519531-.640624-.765625-.914063-1.03125-1.90625-1.984375-3.058594-2.761718-.03125-.023438-.070313-.03125-.101563-.054688-1.113281-.734375-2.34375-1.289062-3.632812-1.726562-.320313-.113282-.632813-.210938-.960938-.296876-1.351562-.367187-2.742187-.632812-4.207031-.632812h-112c-8.832031 0-16 7.167969-16 16s7.167969 16 16 16h73.367188l-95.496094 95.496094c-25.464844-20.367188-56.816406-31.496094-89.871094-31.496094-79.398438 0-144 64.601562-144 144s64.601562 144 144 144 144-64.601562 144-144c0-33.039062-11.121094-64.382812-31.503906-89.871094l95.503906-95.503906v73.375c0 8.832031 7.167969 16 16 16s16-7.167969 16-16v-112c0-.335938-.078125-.65625-.097656-.984375-.023438-.367187-.0625-.71875-.109375-1.078125zm-239.792969 338.0625c-61.761719 0-112-50.238281-112-112s50.238281-112 112-112c29.902344 0 58.054688 11.640625 79.222656 32.734375 21.136719 21.210937 32.777344 49.363281 32.777344 79.265625 0 61.761719-50.238281 112-112 112zm0 0"/>
                                                    </svg>
                                                </b-form-radio>
                                                <b-form-radio class="p-2" value="second">
                                                    <svg height="18pt" viewBox="-56 0 384 384" width="18pt">
                                                        <path d="m272 136c0-74.992188-61.007812-136-136-136s-136 61.007812-136 136c0 69.566406 52.535156 127.015625 120 134.976562v33.023438h-32c-8.832031 0-16 7.167969-16 16s7.167969 16 16 16h32v32c0 8.832031 7.167969 16 16 16s16-7.167969 16-16v-32h32c8.832031 0 16-7.167969 16-16s-7.167969-16-16-16h-32v-33.023438c67.464844-7.960937 120-65.410156 120-134.976562zm-240 0c0-57.34375 46.65625-104 104-104s104 46.65625 104 104-46.65625 104-104 104-104-46.65625-104-104zm0 0"/>
                                                    </svg>
                                                </b-form-radio>
                                            </b-form-radio-group>
                                        </b-form-group>
                                        <b-form-group class="w-50 px-1 mb-1">
                                            <label class="mb-0 text-black-50 w-100">Type:</label>
                                            <b-form-radio-group buttons id="radio-group-2"
                                                                button-variant="outline-secondary" v-model="selected"
                                                                name="radio-sub-component">
                                                <b-form-radio class="p-2" value="first">
                                                    <b-icon icon="chat-dots" font-scale="1.5"
                                                            class="align-self-center"/>
                                                </b-form-radio>
                                                <b-form-radio class="p-2" value="second">
                                                    <b-icon icon="dice-5" font-scale="1.5" class="align-self-center"/>
                                                </b-form-radio>
                                            </b-form-radio-group>
                                        </b-form-group>
                                    </div>

                                    <b-form-group class=" w-50  px-1  mb-1">
                                        <label class="mb-0 text-black-50">Profession:</label>
                                        <b-form-input size="lg" placeholder="Profession"/>
                                    </b-form-group>
                                    <!-- -->
                                    <b-form-group class="w-50 px-1 mb-1">
                                        <label class="mb-0 text-black-50">Ville:</label>
                                        <b-form-input size="lg" placeholder="Ville"/>
                                    </b-form-group>
                                    <b-form-group class=" w-50  px-1  mb-1">
                                        <label class="mb-0 text-black-50">Département:</label>
                                        <b-form-input size="lg" placeholder="Département"/>
                                    </b-form-group>
                                    <!-- -->
                                    <b-form-group class="w-50 px-1 mb-1">
                                        <label class="mb-0 text-black-50">Code Postal:</label>
                                        <b-form-input size="lg" placeholder="Code Postal"/>
                                    </b-form-group>
                                    <b-form-group class=" w-50  px-1  mb-1">
                                        <label class="mb-0 text-black-50">Pays:</label>
                                        <b-form-input size="lg" placeholder="Pays"/>
                                    </b-form-group>
                                    <!-- -->
                                    <b-form-group class="w-100 px-1 mb-1">
                                        <label class="mb-0 text-black-50">Fan de:</label>
                                        <b-form-input size="lg" placeholder="Fan de"/>
                                    </b-form-group>
                                    <b-form-group class="w-100 px-1 mb-1">
                                        <label class="mb-0 text-black-50">Notes:</label>
                                        <b-form-input size="lg" placeholder="Notes"/>
                                    </b-form-group>
                                    <b-form-group class="w-100 px-1 mb-1">
                                        <label class="mb-0 text-black-50">Sujet:</label>
                                        <b-form-textarea size="lg" placeholder="Sujet" style="resize: none;overflow-y: scroll"/>
                                    </b-form-group>
                                    <!-- -->
                                </b-form>
                            </b-card-text>
                        </b-card>
                    </div>
                    <div class="p-1 w-50">
                        <b-card no-body class=" p-1" style="background-color: rgba(0, 0, 0, 0.03)">
                            <b-card-title class="mb-1 title-style text-center">Coordonnées</b-card-title>
                            <b-card-text class="mb-2">
                                <b-form class="d-flex flex-wrap">
                                    <b-form-group class="w-50 px-1 mb-1">
                                        <label class="mb-0 text-black-50">Numéro personnel:</label>
                                        <b-form-input size="lg" placeholder="Numéro personnel"/>
                                    </b-form-group>
                                    <b-form-group class=" w-50  px-1  mb-1">
                                        <label class="mb-0 text-black-50">Numéro professionnel:</label>
                                        <b-form-input size="lg" placeholder="Numéro professionnel"/>
                                    </b-form-group>
                                    <!-- -->
                                    <b-form-group class="w-50 px-1 mb-1">
                                        <label class="mb-0 text-black-50">Numéro mobile 1:</label>
                                        <b-form-input size="lg" placeholder="Numéro mobile 1"/>
                                    </b-form-group>
                                    <b-form-group class=" w-50  px-1  mb-1">
                                        <label class="mb-0 text-black-50">Numéro mobile 2:</label>
                                        <b-form-input size="lg" placeholder="Numéro mobile 2"/>
                                    </b-form-group>
                                    <!-- -->
                                    <b-form-group class="w-50 px-1 mb-1">
                                        <label class="mb-0 text-black-50">Email:</label>
                                        <b-form-input size="lg" placeholder="Email"/>
                                    </b-form-group>
                                    <b-form-group class=" w-50  px-1  mb-1">
                                        <label class="mb-0 text-black-50">Facebook:</label>
                                        <b-form-input size="lg" placeholder="Facebook"/>
                                    </b-form-group>
                                    <!-- -->
                                    <b-form-group class="w-50 px-1 mb-1">
                                        <label class="mb-0 text-black-50">Twitter:</label>
                                        <b-form-input size="lg" placeholder="Twitter"/>
                                    </b-form-group>
                                    <b-form-group class=" w-50  px-1  mb-1">
                                        <label class="mb-0 text-black-50">Instagram:</label>
                                        <b-form-input size="lg" placeholder="Instagram"/>
                                    </b-form-group>
                                    <!-- -->
                                    <b-form-group class="w-100 px-1 mb-1">
                                        <label class="mb-0 text-black-50">Adresse personnelle:</label>
                                        <b-form-input size="lg" class="mb-1" placeholder="Adresse personnelle"/>
                                        <b-form-input size="lg" class="mb-1" placeholder="Adresse personnelle"/>
                                        <b-form-input size="lg" class="mb-1" placeholder="Adresse personnelle"/>
                                    </b-form-group>
                                    <!-- -->
                                    <b-form-group class="w-50 px-1 mb-1">
                                        <label class="mb-0 text-black-50">Code Postal:</label>
                                        <b-form-input size="lg" placeholder="Code Postal"/>
                                    </b-form-group>
                                    <b-form-group class="w-50 px-1 mb-1">
                                        <label class="mb-0 text-black-50">Ville:</label>
                                        <b-form-input size="lg" placeholder="Ville"/>
                                    </b-form-group>
                                    <!-- -->
                                    <b-form-group class=" w-50  px-1  mb-1">
                                        <label class="mb-0 text-black-50">Département:</label>
                                        <b-form-input size="lg" placeholder="Département"/>
                                    </b-form-group>
                                    <b-form-group class=" w-50  px-1  mb-1">
                                        <label class="mb-0 text-black-50">Pays:</label>
                                        <b-form-input size="lg" placeholder="Pays"/>
                                    </b-form-group>
                                    <!-- -->
                                </b-form>
                            </b-card-text>
                        </b-card>
                    </div>
                </div>
                <div class="d-flex ">
                    <div class="p-1 w-50">
                        <b-card no-body class="p-1 " style="background-color: rgba(0, 0, 0, 0.03)">
                            <b-card-title class="mb-1 title-style text-center">Statistiques</b-card-title>
                            <b-card-text>
                                <b-form class="d-flex flex-wrap">
                                    <b-form-group class="w-100 px-1 mb-1">
                                        <b-form-input class="mb-1" placeholder="" disabled/>
                                        <b-form-input class="mb-1" placeholder="" disabled/>
                                        <b-form-input class="mb-1" placeholder="" disabled/>
                                        <b-form-input class="mb-1" placeholder="" disabled/>
                                    </b-form-group>
                                </b-form>
                            </b-card-text>
                        </b-card>
                    </div>
                    <div class="p-1 w-50">
                        <b-card no-body class=" p-1" style="background-color: rgba(0, 0, 0, 0.03)">
                            <b-card-title class="mb-1 title-style text-center">Évaluations</b-card-title>
                            <b-card-text class="mb-2">
                                <b-form class="d-flex flex-wrap">
                                    <b-form-group class=" w-50  px-1  mb-2">
                                        <label class="mb-0 text-black-50">Expression:</label>
                                        <b-form-rating v-model="value"></b-form-rating>
                                    </b-form-group>
                                    <b-form-group class=" w-50  px-1  mb-2">
                                        <label class="mb-0 text-black-50">Qualité du son:</label>
                                        <b-form-rating v-model="value"></b-form-rating>
                                    </b-form-group>
                                    <b-form-group class=" w-50  px-1  mb-2">
                                        <label class="mb-0 text-black-50">Pertinence:</label>
                                        <b-form-rating v-model="value"></b-form-rating>
                                    </b-form-group>
                                  <b-form-group class="w-50 px-1 mb-2">
                                    <label class="mb-0 text-black-50 w-100">Banni:</label>
                                    <b-form-radio-group buttons id="radio-group-2"
                                                        button-variant="outline-danger" v-model="selected"
                                                        name="radio-sub-component">
                                      <b-form-radio class="p-2" value="first">
                                        <b-icon icon="chat-dots" font-scale="1.5"
                                                class="align-self-center"/>
                                      </b-form-radio>
                                      <b-form-radio class="p-2" value="second">
                                        <b-icon icon="dice-5" font-scale="1.5" class="align-self-center"/>
                                      </b-form-radio>
                                    </b-form-radio-group>
                                  </b-form-group>
                                </b-form>
                            </b-card-text>
                        </b-card>
                    </div>
                </div>

            </div>
        </perfect-scrollbar>
        <div class="p-1">
            <b-button>X</b-button>
        </div>
    </div>

</template>

<script>
    // @ is an alias to /src
    /*
    *  IMPORT COMPONENTS
    * */


    /*
    *  IMPORT LIBS
    * */
    import {ConfJson, version} from "@/main";

    import {PerfectScrollbar} from 'vue2-perfect-scrollbar'

    export default {
        name: 'Listener',

        created: function () {
            //console.log(ConfJson)
            let MyComponent = this;
            let myInterval = setInterval(function () {
                if (ConfJson != null) {
                    MyComponent.loading = false;
                    //MyComponent.$store.commit('key/getCallerList');
                    //MyComponent.$store.commit('key/getUpdatedKeys');
                    clearInterval(myInterval);
                }
            }, 100);

        },
        components: {
            PerfectScrollbar,

        },
        props: {
            index: Number
        },
        data: function () {
            return {
                version: version,
                accountName: localStorage.getItem("accountName"),
                loading: true,
                listeners: ["1", "2"],
            }
        },
        computed: {},
        methods: {
            newKey: function (evt) {
                evt.preventDefault();
                this.$store.commit('key/newKey')
            },
            deleteKey: function (name, year, key) {
                this.$store.commit('key/deleteKey', {"name": name, "year": year, "key": key})
            },
            delogMe: function () {
                this.$store.commit('login/delogMe')
            }
        }
    }
</script>

<style scoped>
    .listener-size {
        height: 100%;
        min-height: 800px;
        width: 100%;
    }

    .scroll-size {
        height: calc(100vh - 100px) !important;

    }

    .title-style {
        font-weight: 500;
        font-size: 23pt;
        color: #074B80;
    }
</style>