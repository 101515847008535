<template>
    <b-modal id="modal-function" size="xl">
        <template v-slot:modal-title> {{ $t('modal_function.title') }}</template>
        <div class="d-flex">
            <div class="w-50 mx-1">
                <b-card no-body>
                    <b-card-header class="d-flex justify-content-between">
                        <div class="">GET</div>
                        <b-button variant="success" class="d-flex align-self-center" @click="getFunction(-1, 'get')">
                            <b-icon class="align-self-center" icon="plus" font-scale="1.5"></b-icon>
                        </b-button>

                    </b-card-header>
                    <b-list-group-item v-for="(func, index) in AllFunctionGet" :key="index">
                        <div class="d-flex justify-content-between">
                            <div>
                                {{ func.pk }}
                                -
                                {{ func.label }}
                            </div>
                            <b-button-group>
                                <b-button variant="outline-success" class="d-flex align-self-right"
                                          @click="getSectionIndex(-1)">
                                    <b-icon class="align-self-center" icon="check2" font-scale="1.5"></b-icon>
                                </b-button>
                                <b-button variant="danger" class="d-flex align-self-right"
                                          @click="delFunction(index,'get')">
                                    <b-icon class="align-self-center" icon="trash" font-scale="1.5"></b-icon>
                                </b-button>
                            </b-button-group>
                        </div>
                    </b-list-group-item>
                </b-card>

            </div>
            <div class="w-50 mx-1">
                <b-card no-body>
                    <b-card-header class="d-flex justify-content-between">
                        <div class="">POST</div>
                        <b-button variant="success" class="d-flex align-self-center"
                                  @click="getFunction(-1, 'post')">
                            <b-icon class="align-self-center" icon="plus" font-scale="1.5"></b-icon>
                        </b-button>
                    </b-card-header>
                    <b-list-group-item v-for="(func, index) in AllFunctionPost" :key="index">
                        <div class="d-flex justify-content-between">
                            <div>
                                {{ func.pk }}
                                -
                                {{ func.label }}
                            </div>
                            <b-button-group>
                                <b-button variant="outline-success" class="d-flex align-self-right"
                                          @click="getSectionIndex(-1)">
                                    <b-icon class="align-self-center" icon="check2" font-scale="1.5"></b-icon>
                                </b-button>
                                <b-button variant="danger" class="d-flex align-self-right"
                                          @click="delFunction(index,'post')">
                                    <b-icon class="align-self-center" icon="trash" font-scale="1.5"></b-icon>
                                </b-button>
                            </b-button-group>
                        </div>
                    </b-list-group-item>
                </b-card>
            </div>
        </div>

        <!-- TODO : enelver le footer + afficher la liste + pouvoir en ajouter et en duppliquer >-->
        <!-- <b-list-group-item v-for="(onefunction, index) in function" :key="index">

             {{ onefunction.label }}

         </b-list-group-item>-->
        <template #modal-footer>
            <b-button variant="success">
                <b-icon class="align-self-center" icon="check2" font-scale="1.5"></b-icon>
            </b-button>
        </template>
    </b-modal>
</template>
<script>


    export default {
        name: "ModalFunction",
        components: {},

        data() {
            return {}
        },
        computed: {
            AllFunctionGet() {
                return this.$store.state.users.AllFunctionGet
            },
            AllFunctionPost() {
                return this.$store.state.users.AllFunctionPost
            },
        },


        methods: {
            getFunction: function (index, type) { // pas necessaire
                let func = {}
                if (index <=0) {
                    //var newName = prompt(this.$t('solo.new_file_name'), name);
                    var newName = prompt("Nouvelle fonction : ", name);
                    if (newName == null || newName === "") {
                        return
                    }
                    func = {
                        pk: 0,
                        label: newName,
                        read: true,
                        write: false,
                        fkTbSection: 0,
                        type : type,
                        orederPosition: 0,
                    }

                } else if (type === "get") {
                    func = this.AllFunctionGet[index]
                } else if (type === "post") {
                    func = this.AllFunctionPost[index]
                }
                this.$store.commit('users/insupdFunction', func)
            },
            delFunction: function (index, type) { // pas necessaire
                this.$store.commit('users/delFunction', {index: index, type: type})
            },
        },



    }
</script>